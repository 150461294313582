import { Button, Table, TablePaginationConfig, Tooltip } from "antd";
import { useState } from "react";
import DownloadData from "src/components/modules/parts/DownloadData";
import styled from "styled-components";
import { splitHeaderAndDataForCsv } from "../helpers/promoTableUtils";

const PAGE_SIZE_OPTIONS: any[] = [10, 20, 50, 100, 200];
const PAGINATION_POSITION: any = ["bottomCenter"];
const SCROLL_X = 1300;

interface GlobalTableProps {
  columns: any;
  dataSource: any;
  columKeys: string[];
  handleTablePaginationChange?: (size: number, current: number) => void;
  total?: number;
  currentPage?: number;
}

const GlobalTable = ({
  columns,
  dataSource,
  columKeys,
  handleTablePaginationChange,
  total,
  currentPage,
}: GlobalTableProps) => {
  // row selection and relod indicator state
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [loading, setLoading] = useState(false);
  const [openDownload, setOpenDownload] = useState(false);

  // csv - column and row data
  const [downloadDataHeader, setDownloadDataHeader] = useState<any>([]);
  const [csvData, setCSVData] = useState<any[]>([]);

  const hasSelected = selectedRowKeys.length > 0;

  // set reloding to false after 1s
  const handleReloadSelection = () => {
    setLoading(true);
    // ajax request after empty completing
    setTimeout(() => {
      setSelectedRowKeys([]);
      setLoading(false);
    }, 1000);
  };

  // handle on row selected change
  const onTableSelectionChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: any[]
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
    // custom filed to be printed on csv or pdf

    splitHeaderAndDataForCsv(
      columKeys,
      selectedRows,
      setDownloadDataHeader,
      setCSVData,
      columns
    );
  };

  // on row selected
  const rowSelection = {
    selectedRowKeys,
    onChange: onTableSelectionChange,
  };

  const onTablePaginationChange = (pagination: TablePaginationConfig) => {
    if (pagination && pagination.current) {
      const pageSize = Number(pagination?.pageSize);
      const currentPage = pagination.current;

      return handleTablePaginationChange?.(pageSize, currentPage);
    }
  };

  return (
    <>
      {openDownload && (
        <DownloadData
          header={downloadDataHeader}
          data={csvData}
          open={openDownload}
          setOpen={setOpenDownload}
          type="Promo"
        />
      )}
      <Table
        columns={columns}
        dataSource={dataSource}
        rowSelection={rowSelection}
        onChange={onTablePaginationChange}
        scroll={{ x: SCROLL_X }}
        pagination={{
          position: PAGINATION_POSITION,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
          total: total || undefined,
          current: currentPage || undefined,
        }}
        footer={() => (
          <FooterWrapper>
            <>
              <Tooltip title="Click to reload selected user(s)">
                <Button
                  onClick={handleReloadSelection}
                  disabled={!hasSelected}
                  loading={loading}
                >
                  Reload
                </Button>
              </Tooltip>
              <span className="selected_data">
                {hasSelected
                  ? ` ${selectedRowKeys.length} user(s) selected`
                  : ""}
              </span>
            </>
            <Tooltip title="Click to dwonload selected user(s)">
              <Button
                disabled={!hasSelected}
                onClick={() => setOpenDownload(true)}
              >
                Download
              </Button>
            </Tooltip>
          </FooterWrapper>
        )}
      />
    </>
  );
};

export default GlobalTable;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  .selected_data {
    margin-left: 8px;
  }
`;
