import { CloseOutlined, FilterOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Space } from "antd";
import NewUserModal from "src/components/modules/new-user-modal/NewUserModal";
import GlobalFilter from "src/components/modules/parts/GlobalFilter";
import TableSearchBar from "src/components/modules/parts/SearchBar";
import UsersTable from "src/components/modules/table/custom-table-children/UsersTable";
import { HORIZONTAL_LOADING, USERS } from "src/redux/actionTypes";
import store from "src/redux/store";
import { userAppValues } from "src/util/constants";
import { sortByTimeDesc } from "src/util/util";
import {
  buildQueryParams,
  formatNumberWithCommas,
  objHasAnyValue,
  parseQueryString,
} from "src/util/utility";
import styled from "styled-components";
import {
  filterInputConfigs,
  filterInputProps,
  getInitialfilterValues,
} from "./helpers/filterConfig";
import { useUsersData } from "./helpers/useRQusersData";

export const Users = ({
  dashboard = true,
  activeFilterBox = "setDashbordFilter",
}: {
  dashboard: boolean;
  activeFilterBox: string;
}) => {
  const dispatch = useDispatch();
  const { PayinCountries: baseCurrency } = userAppValues();
  const { currentUsersPage, filterQuery, total } = useSelector(
    (state: any) => state.users
  );

  const [filterQueryParams, setFilterQueryParams] = useState(filterQuery);

  const { data, isFetching, isLoading } = useUsersData(filterQueryParams);
  const { collections } = data || {};
  const users = sortByTimeDesc(collections);

  useEffect(() => {
    if (isFetching || isLoading) {
      store.dispatch({ type: HORIZONTAL_LOADING, payload: true });
    } else {
      store.dispatch({ type: HORIZONTAL_LOADING, payload: false });
    }
  }, [isFetching, isLoading]);

  const [openUserModal, setOpenUserModal] = useState(false);
  const [searchValue, setSearchValue] = useState(
    parseQueryString(filterQuery)?.search || ""
  );
  const [activeTableTab, setActiveTableTab] = useState(
    parseQueryString(filterQuery)?.status || ""
  );

  const [filterValues, setFilterValues] = useState<filterInputProps | any>(
    getInitialfilterValues(filterQuery)
  );

  const [toggleFilter, setToggleFilter] = useState(
    objHasAnyValue(filterValues)
  );

  const handleSearchInputChange = (event: any) => {
    setSearchValue(event.target.value);
    updateStoreSearchValue(event.target.value);
  };

  const handleSearchCancelClick = () => {
    setSearchValue("");
    updateStoreSearchValue("");
  };

  const updateStoreSearchValue = (value: string) => {
    const usersState = store.getState().users;

    setFilterQueryParams((prev: any) => {
      const parsePrevString = parseQueryString(prev);
      const newQuery = {
        ...parsePrevString,
        search: value,
      };

      return buildQueryParams(newQuery);
    });

    dispatch({
      type: USERS,
      payload: {
        ...usersState,
        currentUsersPage: 1, //reset current page for new offset calc
        search: value,
      },
    });
  };

  const Adduser = () => {
    return (
      <Button
        type="primary"
        size="large"
        className="btn_contained"
        //style={{ height: 48 }}
        onClick={() => {
          setOpenUserModal((prev) => !prev);
        }}
      >
        Add User
      </Button>
    );
  };

  const handleFilter = (values: { [key: string]: string }) => {
    handleSearchCancelClick();
    setFilterValues(values);

    setFilterQueryParams((prev: string) => {
      const parsePrevString = parseQueryString(prev);
      const newQueryParams = {
        ...parsePrevString,
        ...values,
      };
      return buildQueryParams(newQueryParams);
    });
  };

  const handleFilterReset = () => {
    handleSearchCancelClick();
    setFilterQueryParams((prev: string) => {
      const parsePrevString = parseQueryString(prev);
      delete parsePrevString?.names;
      delete parsePrevString?.customerID;
      delete parsePrevString?.dateFrom;
      delete parsePrevString?.dateTo;
      delete parsePrevString?.customerKYC;
      delete parsePrevString?.riskScore;
      delete parsePrevString?.country;
      delete parsePrevString?.status;

      return buildQueryParams(parsePrevString);
    });
  };

  return (
    <Wrapper>
      <NewUserModal open={openUserModal} setOpen={setOpenUserModal} />

      <PageHeader>
        <PageTotalData>Users ({formatNumberWithCommas(total)})</PageTotalData>

        {dashboard && (
          <FilterButton
            size="large"
            onClick={() => setToggleFilter((prev) => !prev)}
            type={toggleFilter ? "primary" : "default"}
            ghost={toggleFilter ? true : false}
          >
            <Space>
              Filter
              {toggleFilter ? <CloseOutlined /> : <FilterOutlined />}
            </Space>
          </FilterButton>
        )}
      </PageHeader>

      {toggleFilter && (
        <GlobalFilter
          inputs={filterInputConfigs(filterValues, baseCurrency as any)}
          onSearchClick={handleFilter}
          onSelectCleared={handleFilter}
          onResetClick={() => handleFilterReset()}
          isRequestLoading={isLoading}
          initialInputValuesFromState={getInitialfilterValues(filterQuery)}
        />
      )}

      <TableWrapper>
        <div className="_header">
          <TableSearchBar
            value={searchValue}
            placeholder="Search by firstname or lastname"
            onChange={handleSearchInputChange}
            onCancel={handleSearchCancelClick}
          />

          <Adduser />
        </div>

        <UsersTable
          currentUsersPage={currentUsersPage}
          tableBody={users}
          setFilterQueryParams={setFilterQueryParams}
        />
      </TableWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  height: auto;
  transition: height 0.5s ease-in-out;

  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
`;

const TableWrapper = styled.div`
  border: 1px solid rgba(144, 152, 178, 0.3);
  border-radius: 8px;
  transition: all 5s ease-in-out;
  height: auto;

  ._header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 32px 24px;

    flex-wrap: wrap;
    gap: 12px;
  }

  ._tabs {
    margin: 8px 24px;
  }
`;

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 12px;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
`;

const PageTotalData = styled.h3`
  font-weight: 400;
  font-size: clamp(18px, 4vw, 28px);
  line-height: 18px;
  color: #424242;
`;

export const FilterButton = styled(Button)`
  background-color: rgba(245, 247, 247, 1) !important;
  border: none;
  height: 48px !important;
  border-radius: 999px !important;
  padding-inline: 24px !important;
`;
