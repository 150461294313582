import { UploadOutlined } from "@ant-design/icons";
import { Badge, Dropdown, Space, theme, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { IoIosMore } from "react-icons/io";
import { replaceUnderscores } from "src/util/util";

interface PromoCodesDataType {
  key: React.Key;
  promoCode: string;
  customerId: string;
  minSpend: string;
  usageCount: number;
  value: string;
  lastDateUsed: string;
  expiryDate: string;
  status: string;
}

interface ReferralsDataType {
  key: React.Key;
  refferalCode: string;
  customerId: string;
  progress: string;
  //refreeBonus: string;
  referralBonus: string;
  activationDate: string;
  expiryDate: string;
  status: string;
}

interface VoucherDataType {
  key: React.Key;
  customerId: string;
  totalPoints: string;
  dateActivated: string;
  dateUsed: string;
  expiryDate: string;
  status: string;
}

interface PromosDataType {
  key: React.Key;
  name: string;
  code: string;
  type: string;
  value: string;
  date: string;
  status: string;
  action: string;
}

export const promoColumns = (
  setOpenUpdatePromo: React.Dispatch<React.SetStateAction<boolean>>,
  setPromoData: React.Dispatch<React.SetStateAction<any>>,
  token: any
): ColumnsType<PromosDataType> => {
  return [
    {
      title: "NAME",
      dataIndex: "name",
      width: 250,
      ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
    },
    {
      title: "CODE",
      dataIndex: "code",
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (type) => (
        <div style={{ textTransform: "capitalize" }}>
          {replaceUnderscores(type)?.toLowerCase()}
        </div>
      ),
    },
    {
      title: "VALUE",
      dataIndex: "value",
    },
    {
      title: "END DATE",
      dataIndex: "date",
    },
    {
      title: "STATUS",
      dataIndex: "status",
      render: (status) => (
        <Badge
          status={
            (status === "active" && "success") ||
            (status === "expired" && "error") ||
            "default"
          }
          text={
            (status === "active" && "Active") ||
            (status === "expired" && "Expired") ||
            status
          }
        />
      ),
    },
    {
      title: "ACTION",
      dataIndex: "action",
      fixed: "right",
      width: 100,
      render: (action) => {
        // customized action dropdown style

        const dropdownStyle = {
          container: {
            backgroundColor: token.colorBgElevated,
            borderRadius: token.borderRadiusLG,
            boxShadow: token.boxShadowSecondary,
            padding: "18px",
            width: "auto",
          },
        };

        return (
          <Space size="middle" onClick={(e) => e.stopPropagation()}>
            <Dropdown
              dropdownRender={() => (
                <div style={dropdownStyle.container}>
                  <a
                    onClick={() => {
                      setOpenUpdatePromo(true);
                    }}
                  >
                    <Space>
                      <UploadOutlined />
                      Update promo
                    </Space>
                  </a>
                </div>
              )}
            >
              <a
                onMouseEnter={() =>
                  setPromoData({ ...action, ...action?.settings })
                }
              >
                <IoIosMore size={24} color="#CCCCCC" />
              </a>
            </Dropdown>
          </Space>
        );
      },
    },
  ];
};

export const promoCodeUsagecolumns: ColumnsType<PromoCodesDataType> = [
  {
    title: "PROMO CODE",
    dataIndex: "promoCode",
  },
  {
    title: "CUSTOMER ID",
    dataIndex: "customerId",
  },
  {
    title: "MIN. SPEND",
    dataIndex: "minSpend",
  },
  {
    title: "USAGE COUNT",
    dataIndex: "usageCount",
  },
  {
    title: "VALUE",
    dataIndex: "value",
  },
  {
    title: "LAST DATE USED",
    dataIndex: "lastDateUsed",
  },
  {
    title: "EXPIRY DATE",
    dataIndex: "expiryDate",
  },
  {
    title: "STATUS",
    dataIndex: "status",
    render: (status) => {
      const statusToCapitalize = status?.toUpperCase();
      return (
        <Badge
          status={
            (statusToCapitalize === "ACTIVE" && "success") ||
            (statusToCapitalize === "EXPIRED" && "error") ||
            "default"
          }
          text={statusToCapitalize}
        />
      );
    },
  },
];

export const referralsUsagecolumns: ColumnsType<ReferralsDataType> = [
  {
    title: "REFERRAL CODE",
    dataIndex: "refferalCode",
  },
  {
    title: "CUSTOMER ID",
    dataIndex: "customerId",
  },
  {
    title: "PROGRESS (%)",
    dataIndex: "progress",
  },
  // {
  //   title: "REFEREE BONUS",
  //   dataIndex: "refreeBonus",
  // },
  {
    title: "REFERRAL BONUS",
    dataIndex: "referralBonus",
  },
  {
    title: "ACTIVATION DATE",
    dataIndex: "activationDate",
  },
  {
    title: "EXPIRY DATE",
    dataIndex: "expiryDate",
  },
  {
    title: "STATUS",
    dataIndex: "status",
    render: (status) => {
      const statusToCapitalize = status?.toUpperCase();
      return (
        <Badge
          status={
            (statusToCapitalize === "ACTIVE" && "success") ||
            (statusToCapitalize === "EXPIRED" && "error") ||
            "default"
          }
          text={statusToCapitalize}
        />
      );
    },
  },
];

export const vouchersUsagecolumns: ColumnsType<VoucherDataType> = [
  {
    title: "CUSTOMER ID",
    dataIndex: "customerId",
  },
  {
    title: "TOTAL POINTS",
    dataIndex: "totalPoints",
  },
  {
    title: "DATE ACTIVATED",
    dataIndex: "dateActivated",
  },
  {
    title: "DATE USED",
    dataIndex: "dateUsed",
  },
  {
    title: "EXPIRY DATE",
    dataIndex: "expiryDate",
  },
  {
    title: "STATUS",
    dataIndex: "status",
    render: (status) => {
      const statusToCapitalize = status?.toUpperCase();
      return (
        <Badge
          status={
            (statusToCapitalize === "ACTIVE" && "success") ||
            (statusToCapitalize === "EXPIRED" && "error") ||
            "default"
          }
          text={statusToCapitalize}
        />
      );
    },
  },
];
