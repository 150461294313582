import { parseQueryString } from "src/util/utility";

export interface filterInputProps {
  names?: string;
  customerID?: string;
  country?: string;
  dateFrom?: string;
  dateTo?: string;
  customerKYC?: string;
  riskScore?: string;
}

interface Country {
  name: string;
  countryCode: string;
}

export interface filterInputConfigProps {
  key: string;
  placeholder: string;
  label: string;
  type: string;
  initialValue?: string;
  disabled?: boolean;
  options?: { label: string; value: string }[];
}

export const filterInputConfigs = (
  initialValues: any,
  countries: Country[]
): filterInputConfigProps[] => {
  const arr: filterInputConfigProps[] = [
    // {
    //   key: "names",
    //   placeholder: "Enter name",
    //   label: "Name",
    //   type: "text",
    //   initialValue: initialValues?.names,
    // },
    {
      key: "customerID",
      placeholder: "Enter customer ID",
      label: "Customer ID",
      type: "text",
      initialValue: initialValues?.customerID,
    },
    {
      key: "country",
      placeholder: "Choose country",
      label: "Country",
      type: "select",
      disabled: true,
      initialValue: initialValues?.country,
      options: countries.map((country) => ({
        label: country.name,
        value: country.countryCode,
      })),
    },
    {
      key: "dateFrom",
      placeholder: "Choose a date",
      label: "Date From",
      type: "date",
      initialValue: initialValues?.dateFrom,
    },
    {
      key: "dateTo",
      placeholder: "Choose a date",
      label: "Date To",
      type: "date",
      initialValue: initialValues?.dateTo,
    },
    {
      key: "customerKYC",
      placeholder: "Choose state",
      label: "Customer KYC",
      type: "select",
      initialValue: initialValues?.customerKYC,
      options: [
        { label: "L1 Unverified", value: "L1_Unverified" },
        { label: "L1 Partially Verified", value: "L1_Partially_Verified" },
        { label: "L1 Verified", value: "L1_Verified" },
        { label: "L2 Unverified", value: "L2_Unverified" },
        { label: "L2 Verified", value: "L2_Verified" },
      ],
    },
    {
      key: "riskScore",
      placeholder: "Choose risk score",
      label: "Risk Score",
      type: "select",
      initialValue: initialValues?.riskScore,
      options: [
        { label: "0", value: "zero" },
        { label: "100", value: "100" },
        { label: "200", value: "200" },
        { label: "300", value: "300" },
        { label: "400", value: "400" },
        { label: "500", value: "500" },
      ],
    },
    {
      key: "status",
      placeholder: "Choose status",
      label: "Status",
      type: "select",
      initialValue: initialValues?.status,
      options: [
        { label: "Active", value: "ACTIVE" },
        { label: "Suspended", value: "SUSPENDED" },
        { label: "Temporarily Locked", value: "TEMPORARILY_LOCKED" },
        { label: "Watchlist Hit", value: "WATCHLIST_HIT" },
        { label: "Deleted", value: "DELETED" },
      ],
    },
  ];

  return arr;
};

export const getInitialfilterValues = (filterQuery: any) => {
  const perseFilterQuery = parseQueryString(filterQuery);
  return {
    names: perseFilterQuery?.names ?? "",
    customerID: perseFilterQuery?.customerID ?? "",
    country: perseFilterQuery?.country ?? "",
    dateFrom: perseFilterQuery?.dateFrom ?? "",
    dateTo: perseFilterQuery?.dateTo ?? "",
    customerKYC: perseFilterQuery?.customerKYC ?? "",
    riskScore: perseFilterQuery?.riskScore ?? "",
    status: perseFilterQuery?.status ?? "",
  };
};
