import { parseQueryString } from "src/util/utility";

export interface filterInputProps {
  names?: string;
  customerID?: string;
  baseCurrency?: string;
  targetCurrency?: string;
  destinationCountry?: string;
  dateFrom?: string;
  dateTo?: string;
  paymentMethod?: string;
  paymentGateway?: string;
}

interface Country {
  name: string;
  currency: string;
  countryCode: string;
}

export interface filterInputConfigProps {
  key: string;
  placeholder: string;
  label: string;
  type: string;
  initialValue?: string;
  disabled?: boolean;
  options?: { label: string; value: string }[];
}

export const filterInputConfigs = (
  initialValues: any,
  baseCountries: Country[],
  targetCountries: Country[]
): filterInputConfigProps[] => {
  const arr: filterInputConfigProps[] = [
    // {
    //   key: "names",
    //   placeholder: "Enter name",
    //   label: "Name",
    //   type: "text",
    //   initialValue: initialValues?.names,
    // },
    {
      key: "customerID",
      placeholder: "Enter customer ID",
      label: "Customer ID",
      type: "text",
      initialValue: initialValues?.customerID,
    },
    {
      key: "baseCurrency",
      placeholder: "Choose currency",
      label: "Base Currency",
      type: "select",
      initialValue: initialValues?.baseCurrency,
      options: baseCountries.map((country) => ({
        label: country.currency,
        value: country.currency,
      })),
    },
    {
      key: "targetCurrency",
      placeholder: "Choose currency",
      label: "Target Currency",
      type: "select",
      initialValue: initialValues?.targetCurrency,
      options: targetCountries.map((country) => ({
        label: country.currency,
        value: country.currency,
      })),
    },
    {
      key: "dateFrom",
      placeholder: "Choose a date",
      label: "Date From",
      type: "date",
      initialValue: initialValues?.dateFrom,
    },
    {
      key: "dateTo",
      placeholder: "Choose a date",
      label: "Date To",
      type: "date",
      initialValue: initialValues?.dateTo,
    },
    {
      key: "paymentMethod",
      placeholder: "Choose method",
      label: "Payment Method",
      type: "select",
      initialValue: initialValues?.paymentMethod,
      options: [
        { label: "Mobile Money", value: "1" },
        { label: "Bank Transfer", value: "2" },
        { label: "Cash Pick-Up", value: "3" },
      ],
    },
    {
      key: "status",
      placeholder: "Choose status",
      label: "Transfer Status",
      type: "select",
      initialValue: initialValues?.status,
      options: [
        { label: "In progress", value: "IN_PROGRESS" },
        { label: "Complete", value: "COMPLETE" },
        { label: "Cancelled", value: "CANCELLED" },
        { label: "Pending verification", value: "PENDING_VERIFICATION" },
        { label: "Payment completed", value: "PAYMENT_COMPLETED" },
        { label: "Pending documentation", value: "PENDING_DOCUMENTATION" },
        { label: "Payment cancelled", value: "PAYMENT_CANCELLED" },
        { label: "Rejected", value: "REJECTED" },
        { label: "Refunded", value: "REFUNDED" },
        { label: "Pending", value: "PENDING" },
        { label: "Watchlist failed", value: "WATCHLIST_FAILED" },
        { label: "Expired", value: "EXPIRED" },
        { label: "Payment fraud", value: "PAYMENT_FRAUD" },
        { label: "Payment declined", value: "PAYMENT_DECLINED" },
        { label: "Payment processing", value: "PAYMENT_PROCESSING" },
        { label: "Pending approval", value: "PENDING_APPROVAL" },
      ],
    },
    {
      key: "destinationCountry",
      placeholder: "Choose country",
      label: "Destination Country",
      type: "select",
      disabled: true,
      initialValue: initialValues?.destinationCountry,
      options: targetCountries.map((country) => ({
        label: country.name,
        value: country.countryCode,
      })),
    },
    {
      key: "paymentGateway",
      placeholder: "Choose gateway",
      label: "Payment Gateway",
      type: "select",
      initialValue: initialValues?.paymentGateway,
      disabled: true,
      options: [
        { label: "True Layer", value: "TRUELAYER" },
        { label: "Trust Payment", value: "TRUST_PAYMENT" },
        { label: "Interac®", value: "INTERAC" },
        { label: "Axcess Merchant", value: "AXCESS_MERCHANT" },
      ],
    },
  ];

  return arr;
};

export const getInitialfilterValues = (filterQuery: any) => {
  const perseFilterQuery = parseQueryString(filterQuery);
  return {
    names: perseFilterQuery?.names ?? "",
    customerID: perseFilterQuery?.customerID ?? "",
    baseCurrency: perseFilterQuery?.baseCurrency ?? "",
    targetCurrency: perseFilterQuery?.targetCurrency ?? "",
    destinationCountry: perseFilterQuery?.destinationCountry ?? "",
    dateFrom: perseFilterQuery?.dateFrom ?? "",
    dateTo: perseFilterQuery?.dateTo ?? "",
    status: perseFilterQuery?.status ?? "",
    paymentMethod: perseFilterQuery?.paymentMethod ?? "",
    paymentGateway: perseFilterQuery?.paymentGateway ?? "",
  };
};
