export const splitHeaderAndDataForCsv = (
  keys: string[],
  data: Record<string, any>[],
  setHeader: (header: string[]) => void,
  setCSVData: (csvData: Record<string, any>[]) => void,
  columns: any[]
) => {
  // Map keys to column titles
  const newHeader: string[] = keys.map((key) => {
    const column = columns.find((col) => col.dataIndex === key);
    return column ? column.title : `Column with key '${key}' not found.`;
  });

  // Map data to match the new header structure
  const newCSVData: Record<string, any>[] = data.map((item) => {
    const row: Record<string, any> = {};
    keys.forEach((key) => {
      const column = columns.find((col) => col.dataIndex === key);
      if (column) {
        row[column.title] = item[key];
      }
    });
    return row;
  });

  // Set the header and CSV data
  setHeader(newHeader);
  setCSVData(newCSVData);
};

const isObjEmpty = (obj: any) => {
  return Object.keys(obj).length === 0;
};
