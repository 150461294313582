import { useMutation, useQuery } from "react-query";
import { queryClient } from "src/App";
import { toastAction } from "src/redux/actions/actions";
import { TRANSFER } from "src/redux/actionTypes";
import store from "src/redux/store";
import endpoints from "src/util/endpoints";
import { getRequest, putRequest } from "src/util/http";
import { parseEndpointParameters } from "src/util/util";

export const useTransactionsData = (filterQueryParams = "") => {
  const transfersState = store.getState().transfer;

  const customEndpoint = `${endpoints.TRANSFERS}?${filterQueryParams}`;

  return useQuery(
    customEndpoint,
    () => getRequest(customEndpoint, "Failed to fetch transactions"),
    {
      refetchIntervalInBackground: true,
      keepPreviousData: true,
      onSuccess: (data) => {
        store.dispatch({
          type: TRANSFER,
          payload: {
            ...transfersState,
            total: data?.total || 0,
            queryKey: customEndpoint,
            filterQuery: filterQueryParams || "",
          },
        });
      },
    }
  );
};

export const useUpdateTransfersStatus = (
  currentTransferQkey: string,
  onSuccess: Function
) => {
  return useMutation(
    (values: any) => {
      return putRequest(
        values,
        parseEndpointParameters(endpoints.ADMIN_UPDATE),
        "Failed to update transaction status, Pls try again"
      );
    },
    {
      onSuccess: () => {
        toastAction({
          show: true,
          type: "success",
          timeout: 10000,
          message: "Transcation status updated",
        });
        queryClient.invalidateQueries(currentTransferQkey);
        onSuccess();
      },
    }
  );
};
