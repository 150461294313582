import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Card, Modal, Space, Statistic, Table, Tooltip } from "antd";
import { CustomError, CustomLoader } from "src/util/Reuseables";
import { formatCurrency, formatNumberWithCommas } from "src/util/utility";
import styled from "styled-components";
import {
  formatTimeAgo,
  getBalanceHelpMessage,
  getCardColor,
  useQuickRemitData,
} from "./helper";

interface RateAndBalProps {
  open: boolean;
  onClose: () => void;
}

const exchangeRatesColumns = [
  { title: "Currency", dataIndex: "currency", key: "currency", width: 220 },
  { title: "Rate", dataIndex: "rate", key: "rate" },
];

const RateAndBal = ({ open, onClose }: RateAndBalProps) => {
  const {
    data,
    isLoading,
    isFetching,
    refetch,
    isError,
    error,
    dataUpdatedAt,
  } = useQuickRemitData(open);
  const err: any = error;
  const { accountBalance, fxRates } = data || {};

  const balanceData = [
    {
      title: "Available Balance",
      value: accountBalance?.availableBalance ?? 0,
    },
    { title: "Ledger Balance", value: accountBalance?.ledgerBalance ?? 0 },
    { title: "Hold", value: accountBalance?.hold ?? 0 },
    { title: "Pending", value: accountBalance?.pending ?? 0 },
  ];

  const exchangeRatesData = fxRates
    ? Object.entries(fxRates).map(([currency, rate], index) => ({
        key: currency + index.toString(),
        currency,
        rate: formatNumberWithCommas(Number(rate)),
      }))
    : [];

  if (!open) return null;

  return (
    <Modal
      title="Rates & Balances"
      open={open}
      onCancel={onClose}
      footer={null}
      width={800}
    >
      <ContentStyled>
        {isError && (
          <CustomError message={err?.message} retryAction={() => refetch()} />
        )}
        {isLoading && <CustomLoader prompt="Loading quick-remit data..." />}
        {!isLoading && !isError && (
          <div className="_data">
            <BalancesStyled>
              {balanceData.map((item, index) => (
                <BalanceItem
                  key={index}
                  title={item.title}
                  value={item.value}
                  currency={accountBalance?.currency}
                />
              ))}
            </BalancesStyled>
            <Table
              className="_table"
              title={() => "Exchange Rates"}
              bordered
              columns={exchangeRatesColumns}
              dataSource={exchangeRatesData}
              pagination={false}
            />
            <Space className="last-updated">
              <i>Last updated: {formatTimeAgo(dataUpdatedAt)}</i>
              <Button loading={isFetching} onClick={() => refetch()}>
                Refresh
              </Button>
            </Space>
          </div>
        )}
      </ContentStyled>
    </Modal>
  );
};

const BalanceItem = ({
  title,
  value = 0,
  currency = "NGN",
}: {
  title: string;
  value: string | number;
  currency?: string;
}) => {
  return (
    <Card className="balance-card" style={{ background: getCardColor(title) }}>
      <Statistic
        title={
          <Space>
            {title}
            <Tooltip className="tooltip" title={getBalanceHelpMessage(title)}>
              <QuestionCircleOutlined />
            </Tooltip>
          </Space>
        }
        value={`${formatCurrency(value, currency)}`}
      />
    </Card>
  );
};

export default RateAndBal;

const ContentStyled = styled.div`
  margin: 20px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;

  ._data {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 32px;
    ._table {
      width: 100%;
    }

    i {
      text-align: right;
      color: #707070;
      margin: 0;
    }
  }
`;

const BalancesStyled = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 14px;

  .balance-card {
    display: flex;
    flex: 1;
    flex-basis: 350px;

    .tooltip {
      cursor: help;
    }
  }
`;
