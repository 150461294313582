import { CloseOutlined, FilterOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GlobalFilter from "src/components/modules/parts/GlobalFilter";
import TableSearchBar from "src/components/modules/parts/SearchBar";
import TransfersTable from "src/components/modules/table/custom-table-children/TransfersTable";
import { HORIZONTAL_LOADING, TRANSFER } from "src/redux/actionTypes";
import store from "src/redux/store";
import { userAppValues } from "src/util/constants";
import {
  buildQueryParams,
  formatNumberWithCommas,
  getAllUniqueCurrencies,
  objHasAnyValue,
  parseQueryString,
} from "src/util/utility";
import styled from "styled-components";
import { FilterButton } from "../users/Users";
import {
  filterInputConfigs,
  getInitialfilterValues,
} from "./components/helpers/filterConfig";
import { useTransactionsData } from "./components/helpers/useRQtransfersData";

export const Transfers = ({
  dashboard = true,
  activeFilterBox = "dashbordFilter",
}: {
  dashboard: boolean;
  activeFilterBox: string;
}) => {
  const dispatch = useDispatch();
  const { PayoutCountries: targetCurrencies, PayinCountries: baseCurrrencies } =
    userAppValues();

  const { filterQuery, currentTransactionsPage, total } =
    useSelector((state: any) => state.transfer) || {};

  const [tUserSelectorOpen, setTUserSelectorOpen] = useState(false);
  const [searchValue, setSearchValue] = useState(
    parseQueryString(filterQuery)?.search || ""
  );

  const [activeTableTab, setActiveTableTab] = useState(
    parseQueryString(filterQuery)?.status || ""
  );

  const [filterQueryParams, setFilterQueryParams] = useState(filterQuery);
  const [filterValues, setFilterValues] = useState<any>(
    getInitialfilterValues(filterQuery)
  );

  const [toggleFilter, setToggleFilter] = useState(
    objHasAnyValue(filterValues)
  );

  const { data, isError, isLoading, isFetching } =
    useTransactionsData(filterQueryParams);

  const { collections: transactions } = data || {};

  useEffect(() => {
    if (isFetching || isLoading) {
      store.dispatch({ type: HORIZONTAL_LOADING, payload: true });
    } else {
      store.dispatch({ type: HORIZONTAL_LOADING, payload: false });
    }
  }, [isFetching, isLoading]);

  const handleSearchInputChange = (event: any) => {
    setSearchValue(event.target.value);
    updateStoreSearchValue(event.target.value);
  };

  const handleClearInput = () => {
    setSearchValue("");
    updateStoreSearchValue("");
  };

  const updateStoreSearchValue = (value: string) => {
    const transferState = store.getState().transfer;

    setFilterQueryParams((prev: any) => {
      const parsePrevString = parseQueryString(prev);
      const newQuery = {
        ...parsePrevString,
        transactionId: value,
      };

      return buildQueryParams(newQuery);
    });

    dispatch({
      type: TRANSFER,
      payload: {
        ...transferState,
        currentUsersPage: 1, //reset current page for new offset calc
        search: value,
      },
    });
  };

  const handleFilter = (values: { [key: string]: string }) => {
    handleClearInput();
    setFilterValues(values);

    setFilterQueryParams((prev: string) => {
      const parsePrevString = parseQueryString(prev);
      const newQueryParams = {
        ...parsePrevString,
        ...values,
      };
      return buildQueryParams(newQueryParams);
    });
  };

  const handleFilterReset = () => {
    handleClearInput();
    setFilterQueryParams((prev: string) => {
      const parsePrevString = parseQueryString(prev);
      delete parsePrevString?.names;
      delete parsePrevString?.customerID;
      delete parsePrevString?.baseCurrency;
      delete parsePrevString?.targetCurrency;
      delete parsePrevString?.destinationCountry;
      delete parsePrevString?.dateFrom;
      delete parsePrevString?.dateTo;
      delete parsePrevString?.paymentMethod;
      delete parsePrevString?.paymentGateway;
      delete parsePrevString?.status;

      return buildQueryParams(parsePrevString);
    });
  };

  const NewTransfers = () => {
    return (
      <Button
        type="primary"
        size="large"
        onClick={() => setTUserSelectorOpen(!tUserSelectorOpen)}
        className="btn_contained"
      >
        New transfer
      </Button>
    );
  };

  return (
    <Wrapper>
      <PageHeader>
        <PageTotalData>
          Transfers ({formatNumberWithCommas(total)})
        </PageTotalData>

        {dashboard && (
          <FilterButton
            size="large"
            onClick={() => setToggleFilter((prev) => !prev)}
            type={toggleFilter ? "primary" : "default"}
            ghost={toggleFilter ? true : false}
          >
            <Space>
              Filter
              {toggleFilter ? <CloseOutlined /> : <FilterOutlined />}
            </Space>
          </FilterButton>
        )}
      </PageHeader>

      {toggleFilter && (
        <GlobalFilter
          inputs={filterInputConfigs(
            filterValues,
            getAllUniqueCurrencies(baseCurrrencies),
            targetCurrencies
          )}
          onSearchClick={handleFilter}
          onSelectCleared={handleFilter}
          onResetClick={() => handleFilterReset()}
          isRequestLoading={isLoading}
          initialInputValuesFromState={getInitialfilterValues(filterQuery)}
        />
      )}

      <TableWrapper>
        <div className="_header">
          <TableSearchBar
            value={searchValue}
            placeholder="Search by transaction Id or first name"
            onChange={handleSearchInputChange}
            onCancel={handleClearInput}
          />

          {/* {dashboard && <SelectDays days={days} setDays={setDays} />} */}

          <NewTransfers />
        </div>

        <TransfersTable
          tableBody={transactions}
          currentTransactionsPage={currentTransactionsPage}
          setFilterQueryParams={setFilterQueryParams}
        />
      </TableWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  height: auto;
  transition: height 0.5s ease-in-out;

  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
`;

const TableWrapper = styled.div`
  border: 1px solid rgba(144, 152, 178, 0.3);
  border-radius: 8px;
  transition: all 5s ease-in-out;
  height: auto;

  ._header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 32px 24px;

    flex-wrap: wrap;
    gap: 12px;
  }

  ._tabs {
    margin: 8px 24px;
  }
`;

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 12px;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
`;

const PageTotalData = styled.h3`
  font-weight: 400;
  font-size: clamp(18px, 4vw, 28px);
  line-height: 18px;
  color: #424242;
`;
