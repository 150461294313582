import { theme } from "antd";
import { useEffect, useState } from "react";
import NewPromoModal from "src/components/modules/new-promo-modal/NewPromoModal";
import { sortByTimeDesc } from "src/util/util";
import { promoColumns } from "../helpers/TableColumns";
import { usePromosData } from "../helpers/useRQpromoData";
import GlobalTable from "./GlobalTable";

const { useToken } = theme;

const PromoTables = ({
  data,
  filterQuery,
}: {
  data?: any;
  filterQuery?: string;
}) => {
  const [openUpdatePromo, setOpenUpdatePromo] = useState(false);
  const [promoData, setPromoData] = useState<Record<any, any>>({});
  const [dataSrc, setDataScr] = useState<any>([]);
  const { token } = useToken();

  const columns = promoColumns(setOpenUpdatePromo, setPromoData, token);
  const columnKeys = ["name", "code", "type", "value", "date", "status"];

  const { data: rqPromoData } = usePromosData("limit=20&offset=0");
  const { collections, limit, offset, pages, total } = rqPromoData || {};
  const promosArray = collections || [];

  useEffect(() => {
    const dataSource = sortByTimeDesc(promosArray as any[])?.map(
      (item, index) => {
        return {
          key: index,
          name: item?.name || "--",
          code: item?.code || "--",
          type: item?.type || "--",
          value: `${
            item.settings?.rate ||
            item.settings?.percentage ||
            item.settings?.discountAmount ||
            "--"
          }`,
          date: item?.endDate || "--",
          status: item?.status || "--",
          action: item,
        };
      }
    );
    setDataScr(dataSource);
  }, [promosArray]);

  return (
    <>
      <NewPromoModal
        open={openUpdatePromo}
        setOpen={setOpenUpdatePromo}
        data={promoData}
      />

      <GlobalTable
        columns={columns}
        dataSource={dataSrc}
        columKeys={columnKeys}
        handleTablePaginationChange={(size, current) => {
          console.log(size, current);
        }}
      />
    </>
  );
};

export default PromoTables;
