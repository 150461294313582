import React, { useEffect } from "react";
import { SearchOutlined, CloseOutlined } from "@ant-design/icons";
import { Input, Button } from "antd";

const prefix = (
  <SearchOutlined
    style={{
      fontSize: 20,
      color: "#808080",
      paddingRight: "5px",
      paddingLeft: "5px",
    }}
  />
);

interface SearchProps {
  value: string;
  placeholder: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onCancel: () => void;
}

export default function TableSearchBar({
  value,
  placeholder,
  onChange,
  onCancel,
}: SearchProps) {
  const [timer, setTimer] = React.useState<NodeJS.Timeout | null>(null);
  // State to keep track of the typed value
  const [typedValue, setTypedValue] = React.useState(value);

  useEffect(() => {
    setTypedValue(value);
  }, [value]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    // Update the typedValue immediately
    setTypedValue(newValue);

    // Clear the existing timer (if any)
    if (timer) {
      clearTimeout(timer);
    }

    // Set a new timer for 2000 milliseconds (2 seconds)
    const newTimer = setTimeout(() => {
      // Call the onChange prop after 2 seconds of inactivity
      onChange(e);
    }, 500);

    // Update the timer state with the new timer
    setTimer(newTimer);
  };

  const handleCancel = () => {
    // Clear the existing timer (if any)
    if (timer) {
      clearTimeout(timer);
    }
    // Reset the typedValue and value when the user clicks cancel
    setTypedValue("");
    onCancel();
  };

  const suffix = (
    <Button
      type="text"
      style={{ display: value === "" ? "none" : "block" }}
      onClick={handleCancel}
    >
      <CloseOutlined
        style={{
          fontSize: 16,
          color: "#808080",
        }}
      />
    </Button>
  );

  return (
    <div>
      <Input
        placeholder={placeholder}
        value={typedValue}
        size="large"
        prefix={prefix}
        suffix={suffix}
        onChange={handleOnChange}
        style={{
          //padding: 12.5,
          width: "300px",
          borderRadius: "200px",
          height: 44,
        }}
      />
    </div>
  );
}
