import { useQuery } from "react-query";
import { PROMOS, USERS } from "src/redux/actionTypes";
import store from "src/redux/store";
import endpoints from "src/util/endpoints";
import { getRequest } from "src/util/http";
import { sortByTimeDesc } from "src/util/util";

export const usePromosData = (filterQueryParams = "") => {
  const promos = store.getState().promos;

  const customEndpoint = `${endpoints.PROMOS}?${filterQueryParams}`;

  return useQuery(
    customEndpoint,
    () => getRequest(customEndpoint, "Failed to fetch promos"),
    {
      refetchIntervalInBackground: true,
      keepPreviousData: true,
      onSuccess: (data) => {
        console.log(data);
        // store.dispatch({
        //   type: PROMOS,
        //   payload: {
        //     ...promos,
        //     // users: sortByTimeDesc(data?.collections),
        //     // total: data?.total || 0,
        //     queryKey: customEndpoint,
        //     filterQuery: filterQueryParams || "",
        //   },
        // });
      },
    }
  );
};
