import { queryClient } from "src/App";
import {
  approveTransferDocumentation,
  approveTransferVerification,
  cancelTransfer,
  completeTransfer,
  markRejectedTransferRefunded,
  rejectTransfer,
  remitFundsWithPivot,
  setTransferToPaymentComplete,
  updateFraudAlertTransfer,
  uploadUserDocument,
} from "src/redux/actions/actions";
import store from "src/redux/store";
import constants from "./constants";
import { compareDatesXLessThanY, getCorrespondingActions } from "./util";

export const isCurrentPath = (path: string, location?: any): boolean => {
  return location.pathname === path || window.location.pathname === path;
};

export const goTo = (path: string, history?: any, newTab?: boolean) => {
  if (!history) {
    if (newTab) {
      return window.open(path, "_blank") || window.location.replace(path);
    }
    window.location.href = path;
    return;
  }
  history?.push(path);
};

export const getImage = (name: string, folder: string = "") => {
  return `/assets/${folder}/${name}`;
};

export const generateRandomString = () => {
  let generatedString = "";
  const characters =
    "abcdefghijklmnopqrstuvwxyz1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ!@#$%^&*?";

  for (let i = 0; i < 10; i++) {
    generatedString += characters.charAt(
      Math.floor(Math.random() * characters.length)
    );
  }
  return generatedString;
};

/// @-Mjas
export function identifyAdmin(roles: []) {
  //const userRole = roles;
  const userRole = roles?.join(",");
  const superAdmin = "admin,super_admin";
  const admin = "admin";
  const technicalAdmin = "technical_admin";

  //console.log("Role: ", userRole);

  if (userRole === superAdmin) return "Super Admin";
  else if (userRole === admin) return "Admin";
  else if (userRole === technicalAdmin) return "Technical Admin";
  else return "New role";
}

export const TransfersTableTabs = [
  {
    label: "All",
    key: "",
    format: "",
  },
  {
    label: "Expired",
    key: constants.TRANSFER_STATUS_EXPIRED,
    format: constants.TRANSFER_STATUS_EXPIRED,
  },
  {
    label: "Complete",
    key: constants.TRANSFER_STATUS_COMPLETE,
    format: constants.TRANSFER_STATUS_COMPLETE,
  },
  {
    label: "Refunded",
    key: constants.TRANSFER_STATUS_REFUNDED,
    format: constants.TRANSFER_STATUS_REFUNDED,
  },
  {
    label: "Cancelled",
    key: constants.TRANSFER_STATUS_CANCELLED,
    format: constants.TRANSFER_STATUS_CANCELLED,
  },
  {
    label: "Bloked",
    key: "BLOCKED",
    format: "BLOCKED",
  },
  {
    label: "Fraud alerts",
    key: constants.TRANSFER_FLAG_FRAUD_ALERT,
    format: constants.TRANSFER_FLAG_FRAUD_ALERT,
  },
  {
    label: "Rejected",
    key: constants.TRANSFER_STATUS_REJECTED,
    format: constants.TRANSFER_STATUS_REJECTED,
  },
  {
    label: "Pending documentation",
    key: constants.TRANSFER_STATUS_PENDING_DOCUMENTATION,
    format: constants.TRANSFER_STATUS_PENDING_DOCUMENTATION,
  },
  {
    label: "Pending verifications",
    key: constants.TRANSFER_STATUS_PENDING_VERIFICATION,
    format: constants.TRANSFER_STATUS_PENDING_VERIFICATION,
  },
  {
    label: "Pending payments",
    key: constants.TRANSFER_STATUS_PENDING,
    format: constants.TRANSFER_STATUS_PENDING,
  },
  {
    label: "Payment fraud",
    key: constants.TRANSFER_STATUS_PAYMENT_FRAUD,
    format: constants.TRANSFER_STATUS_PAYMENT_FRAUD,
  },
  {
    label: "Payments completed",
    key: constants.TRANSFER_STATUS_PAYMENT_COMPLETED,
    format: constants.TRANSFER_STATUS_PAYMENT_COMPLETED,
  },
];

export const Countries = [
  {
    name: "Belgium",
    countryCode: "BE",
    dialCode: "+32",
  },
  {
    name: "Canada",
    countryCode: "CA",
    dialCode: "+1",
  },
  {
    name: "Denmark",
    countryCode: "DK",
    dialCode: "+45",
  },
  {
    name: "Finland",
    countryCode: "FI",
    dialCode: "+358",
  },
  {
    name: "France",
    countryCode: "FR",
    dialCode: "+33",
  },
  {
    name: "Germany",
    countryCode: "DE",
    dialCode: "+49",
  },
  {
    name: "Ireland",
    countryCode: "IE",
    dialCode: "+353",
  },
  {
    name: "Italy",
    countryCode: "IT",
    dialCode: "+39",
  },
  {
    name: "Netherlands",
    countryCode: "NL",
    dialCode: "+31",
  },
  {
    name: "Norway",
    countryCode: "NO",
    dialCode: "+47",
  },
  {
    name: "Spain",
    countryCode: "ES",
    dialCode: "+34",
  },
  {
    name: "Sweden",
    countryCode: "SE",
    dialCode: "+46",
  },
  {
    name: "Switzerland",
    countryCode: "CH",
    dialCode: "+41",
  },
  {
    name: "United Kingdom",
    countryCode: "GB",
    dialCode: "+44",
  },
];

export const getFlagURL = (countryCode: string) => {
  const baseURL = "https://flagcdn.com/h240/";
  const countryCodeLowerCase = countryCode.toLowerCase();
  return `${baseURL}${countryCodeLowerCase}.png`;
};

export function formatUserDOB(
  day: number,
  month: number,
  year: number
): string {
  const months: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const suffixes: string[] = ["st", "nd", "rd", "th"];
  let daySuffix: string;

  if (day >= 11 && day <= 13) {
    daySuffix = "th";
  } else {
    daySuffix = suffixes[(day - 1) % 10] || "th";
  }

  const monthName: string = months[month - 1];
  if (day === undefined || monthName === undefined || year === undefined) {
    return "";
  } else {
    return `${day}${daySuffix} of ${monthName}, ${year}`;
  }
}

export const getTransferActionCallbacks = (transfer: any, flag = "") => {
  const { queryKey } = store.getState().transfer || {};

  const invalidateTransfers = () => queryClient.invalidateQueries(queryKey);

  const actionCallbacks: any = {};
  actionCallbacks[constants.TRANSFER_STATUS_PAYMENT_COMPLETED] = {
    approve: () =>
      remitFundsWithPivot(() => {
        invalidateTransfers();
      }, transfer.id),
    reject: () =>
      completeTransfer(() => {
        invalidateTransfers();
      }, transfer.id),
  };
  actionCallbacks[constants.TRANSFER_STATUS_PAYMENT_SUSPENDED] = {
    approve: () =>
      setTransferToPaymentComplete(() => {
        invalidateTransfers();
      }, transfer.id),
    reject: () =>
      rejectTransfer(() => {
        invalidateTransfers();
      }, transfer.id),
  };
  // actionCallbacks[constants.TRANSFER_FLAG_FRAUD_ALERT] = {
  //   approve: () =>
  //     setTransferToPaymentComplete(() => {
  //       getTransfers();
  //     }, transfer.id),
  //   reject: () =>
  //     cancelTransfer(() => {
  //       getTransfers();
  //     }, transfer.id),
  // };
  actionCallbacks[constants.TRANSFER_FLAG_FRAUD_ALERT] = {
    approve: () =>
      updateFraudAlertTransfer(
        () => {
          invalidateTransfers();
        },
        transfer.id,
        true
      ),
    reject: () =>
      updateFraudAlertTransfer(
        () => {
          invalidateTransfers();
        },
        transfer.id,
        false
      ),
  };
  actionCallbacks[constants.TRANSFER_STATUS_PENDING_APPROVAL] = {
    approve: () =>
      updateFraudAlertTransfer(
        () => {
          invalidateTransfers();
        },
        transfer.id,
        true
      ),
    reject: () =>
      rejectTransfer(() => {
        invalidateTransfers();
      }, transfer.id),
  };
  actionCallbacks[constants.TRANSFER_STATUS_PAYMENT_FRAUD] = {
    approve: () =>
      setTransferToPaymentComplete(() => {
        invalidateTransfers();
      }, transfer.id),
    reject: () =>
      cancelTransfer(() => {
        invalidateTransfers();
      }, transfer.id),
  };
  actionCallbacks[constants.TRANSFER_STATUS_WATCHLIST_FAILED] = {
    approve: () =>
      setTransferToPaymentComplete(() => {
        invalidateTransfers();
      }, transfer.id),
    reject: () =>
      rejectTransfer(() => {
        invalidateTransfers();
      }, transfer.id),
  };
  actionCallbacks[constants.TRANSFER_STATUS_PENDING_DOCUMENTATION] = {
    approve: () =>
      approveTransferDocumentation(
        () => {
          invalidateTransfers();
        },
        transfer.userId,
        true,
        (message: string) => handleApproveDocFailure(message, transfer)
      ),
    reject: () =>
      approveTransferDocumentation(
        () => {
          invalidateTransfers();
        },
        transfer.userId,
        false,
        () => {}
      ),
  };
  actionCallbacks[constants.TRANSFER_STATUS_REJECTED] = {
    approve: () =>
      markRejectedTransferRefunded(() => {
        invalidateTransfers();
      }, transfer.id),
    reject: () =>
      cancelTransfer(() => {
        invalidateTransfers();
      }, transfer.id),
  };
  actionCallbacks[constants.TRANSFER_STATUS_PENDING_VERIFICATION] = {
    approve: () =>
      approveTransferVerification(
        () => {
          invalidateTransfers();
        },
        transfer.userId,
        true
      ),
    reject: () =>
      approveTransferVerification(
        () => {
          invalidateTransfers();
        },
        transfer.userId,
        false
      ),
  };

  const handleApproveDocFailure = (message: string, transfer: any) => {
    if (
      message.indexOf("upload") !== -1 ||
      message.indexOf("document") !== -1
    ) {
      uploadUserDocument({
        open: true,
        user: transfer.userId,
        transfer: transfer,
      });
    }
  };

  // actionCallbacks[constants.TRANSFER_FLAG_FRAUD_ALERT] = {
  //   approve: () =>
  //     updateFraudAlertTransfer(
  //       () => {
  //         getTransfers();
  //       },
  //       transfer.id,
  //       true
  //     ),
  //   reject: () =>
  //     updateFraudAlertTransfer(
  //       () => {
  //         getTransfers();
  //       },
  //       transfer.id,
  //       false
  //     ),
  // };

  const status =
    transfer?.meta?.FRAUD_ALERT === "FRAUD_ALERT"
      ? "FRAUD_ALERT"
      : transfer?.status;

  if (flag) {
    return actionCallbacks[flag];
  }
  return actionCallbacks[status?.toUpperCase()];
};

export const getEqivalentText = (action: any) => {
  const status =
    action?.meta?.FRAUD_ALERT === "FRAUD_ALERT"
      ? "FRAUD_ALERT"
      : action?.status;

  const data = getCorrespondingActions(
    status,
    getTransferActionCallbacks(action)
  );
  return data;
};

export function getFirstLetter(str: string) {
  if (typeof str !== "string" || str.length === 0) {
    return;
  }
  return str[0];
}

export const getPromoStatus = (promo: any) => {
  const today = new Date();
  const todayInString =
    today.getMonth() + 1 + "-" + today.getDate() + "-" + today.getFullYear();
  const expired = compareDatesXLessThanY(promo.endDate, todayInString);
  const notDue = compareDatesXLessThanY(todayInString, promo.startDate);

  return expired ? "expired" : notDue ? "not due" : "active";
};

// function to convert time to regular format "dd/mm/yy"
export function convertTimestampToRegularDate(timestamp: any) {
  const date = new Date(timestamp * 1000);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString().slice(-2);
  const convertedDate = `${day}/${month}/${year}`;
  return convertedDate;
}

// function to get Users verification
export const getUserVerificationsStatuss = (
  user: any
): { completely: boolean; partially: boolean } => {
  // Check if user?.meta?.verified is truthy
  const formSubmitted = Boolean(user?.meta?.verified);

  let verificationList = [];

  let invalidIdVerification: any = {};

  let invalidDocumentVerification: any = {};

  // If user?.verifications exists, populate verificationList with its values
  if (user?.verifications) {
    for (const key in user?.verifications) {
      verificationList.push(user?.verifications[key]);
    }
  }

  if (verificationList.length > 0) {
    verificationList.map((verification) => {
      if (verification.type === "IDENTITY") {
        //idVerification = verification;
        invalidIdVerification =
          verification.status === "PENDING" ||
          verification.status === "INVALID";
      } else if (verification.type === "DOCUMENT") {
        //documentVerification = verification;
        invalidDocumentVerification =
          verification.status === "PENDING" ||
          verification.status === "INVALID";
      }
    });
  }
  const thirdpartyVerificationCompleted =
    !invalidIdVerification && !invalidDocumentVerification;

  const completelyVerified = formSubmitted && thirdpartyVerificationCompleted;
  const partiallyVerified =
    (formSubmitted && !thirdpartyVerificationCompleted) ||
    (!formSubmitted && thirdpartyVerificationCompleted);

  return {
    completely: completelyVerified || false,
    partially: partiallyVerified || false,
  };
};

export const getUserKycVerificationTagColor = (verificationLevel: string) => {
  switch (verificationLevel) {
    case "L1_Partially_Verified":
    case "L2_Partially_Verified":
      return "geekblue";

    case "L1_Verified":
    case "L2_Verified":
      return "green";

    case "L1_Unverified":
    case "L2_Unverified":
      return "red";

    default:
      return "default";
  }
};

export const getUserStatusTagColor = (status: string): string => {
  switch (status) {
    case "ACTIVE":
      return "green"; // Green
    case "SUSPENDED":
      return "warning"; // Yellow/Orange
    case "TEMPORARILY_LOCKED":
      return "processing"; // Blue
    case "WATCHLIST_HIT":
      return "error"; // Red
    case "DUPLICATE":
      return "default"; // Grey
    case "DELETED":
      return "error"; // Red
    default:
      return "default"; // Fallback to Grey
  }
};

export const getUserVerificationsLevel = (
  user: any
): { verificationLevel: string } => {
  const formSubmitted = Boolean(user?.meta?.verified);
  let verificationLevel = "";

  const verificationList: any = Object.values(user?.verifications || {});

  const idVerification = verificationList.find(
    (verification: any) => verification?.type === "IDENTITY"
  );
  const documentVerification = verificationList.find(
    (verification: any) => verification?.type === "DOCUMENT"
  );
  const addressVerification = verificationList.find(
    (verification: any) => verification?.type === "PROOF_OF_ADDRESS"
  );
  const bureauVerificationStatus = verificationList.find(
    (verification: any) => verification?.type === "MULTI_BUREAU"
  );

  if (
    idVerification?.status === "VALID" &&
    documentVerification?.status === "VALID"
  ) {
    verificationLevel = "L1_Verified";
  } else if (
    (idVerification?.status !== "VALID" &&
      documentVerification?.status === "VALID") ||
    (idVerification?.status === "VALID" &&
      documentVerification?.status !== "VALID")
  ) {
    verificationLevel = "L1_Partially_Verified";
  } else {
    verificationLevel = "L1_Unverified";
  }

  if (
    addressVerification?.status === "VALID" &&
    bureauVerificationStatus?.status === "VALID"
  ) {
    verificationLevel = "L2_Verified";
  } else if (
    (bureauVerificationStatus?.status !== "VALID" &&
      addressVerification?.status === "VALID") ||
    (bureauVerificationStatus?.status === "VALID" &&
      addressVerification?.status !== "VALID")
  ) {
    verificationLevel = "L2_Partially_Verified";
  } else if (
    addressVerification === "INVALID" &&
    bureauVerificationStatus === "INVALID"
  ) {
    verificationLevel = "L2_Unverified";
  }

  return {
    verificationLevel,
  };
};

export const checkSubmittedData = (submittedData: any): string => {
  if (submittedData instanceof Object) {
    if (
      submittedData?.exportUrl !== null &&
      submittedData?.exportUrl !== undefined
    ) {
      return "EXPORT_URL";
    }
  } else {
    const jsonObjectSubmittedData = JSON?.parse(submittedData);

    if (
      jsonObjectSubmittedData &&
      Object.keys(jsonObjectSubmittedData)?.length === 1 &&
      jsonObjectSubmittedData["NO DATA STORED"] === ""
    ) {
      return "NO_DATA_STORED";
    }
  }

  return "OLD_SUBMITTED_DATA";
};

const ColorList = [
  "#f56a00",
  "#7265e6",
  "#ffbf00",
  "#00a2ae",
  "#333333", // Dark gray
  "#4a90e2", // Bright blue
  "#d9534f", // Dark red
  "#5bc0de", // Light blue
  "#c8e3e8", // Pale blue
  "#ffc107", // Yellow
  "#28a745", // Green
  "#6610f2", // Purple
  "#FB6A83", // Pink
  "#17a2b8", // Turquoise
  "#343a40", // Black
  "#e83e8c", // Magenta
  "#20c997", // Teal
  "#6c757d", // Gray
  "#dc34a1", // Dark red
  "#fd7e14", // Orange
  "#6610f2", // Indigo
  "#a186c3", // Lavender
  "#20c997", // Mint
  "#d6cd60", // Beige
  "#adb5bd", // Gray
  "#85bb65", // Lime green
  "#cf4b9f", // Orchid
];

export const generateAlphabetColor = (alphabet: string): string => {
  const uppercaseAlphabet = alphabet?.toUpperCase();

  const alphabetIndex = uppercaseAlphabet?.charCodeAt(0) - "A".charCodeAt(0);

  if (alphabetIndex >= 0 && alphabetIndex < ColorList.length) {
    return ColorList[alphabetIndex];
  } else {
    return "#343a40";
  }
};

export const getAllUniqueCurrencies = (PayinCountries: any) => {
  const uniqueCurrencyObjects: { [key: string]: any } = {};

  PayinCountries.forEach((country: any) => {
    const { currency } = country;
    if (!uniqueCurrencyObjects[currency]) {
      uniqueCurrencyObjects[currency] = country;
    }
  });

  const result = Object.values(uniqueCurrencyObjects);
  //   console.log(result);
  return result;
};

export function objHasAnyValue(obj: Record<string, any>): boolean {
  return (
    obj &&
    Object?.values(obj).some(
      (value) => value !== null && value !== undefined && value !== ""
    )
  );
}

export function parseQueryString(query: string): Record<string, string> {
  return query.split("&").reduce((acc, pair) => {
    const [key, value] = pair.split("=");
    if (key) {
      acc[key] = value || ""; // If value is undefined, default to an empty string
    }
    return acc;
  }, {} as Record<string, string>);
}

export const isValueEncoded = (value: string): boolean => {
  try {
    // Decode the value and see if it changes
    return decodeURIComponent(value) !== value;
  } catch {
    // Decoding failed, meaning it was not encoded or encoded incorrectly
    return false;
  }
};

export const formatNumberWithCommas = (value: string | number): string => {
  const valueToString = String(value);

  const [integerPart, fractionalPart] = valueToString.split(".");
  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ","
  );

  return fractionalPart !== undefined
    ? `${formattedIntegerPart}.${fractionalPart}`
    : formattedIntegerPart;
};

export const buildQueryParams = (values: Record<string, any>): string => {
  const queryParams = Object.entries(values)
    .map(([key, value]) => {
      if (value !== undefined && value !== null) {
        const encodedValue = encodeURIComponent(value);
        return `${key}=${encodedValue}`;
      }
      return null;
    })
    .filter((param) => param !== null)
    .join("&");

  return queryParams;
};

export const formatCurrency = (value: number | string, currency: string = "USD") => {
  const valueToNumber = Number(value.toString().replace(",", ""));
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  }).format(valueToNumber);
};