import { DeleteOutlined } from "@ant-design/icons";
import { Button, DatePicker, Input, Select, Space } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const { Option } = Select;

interface InputConfig {
  key: string;
  placeholder?: string;
  label: string;
  type: "text" | "select" | "date" | string;
  options?: { label: string; value: string }[];
  initialValue?: string; // Optional initial value property
  disabled?: boolean;
}

interface GlobalFilterProps {
  inputs: InputConfig[];
  onSearchClick: (values: { [key: string]: string }) => void;
  onResetClick: (values: { [key: string]: string }) => void;
  onSelectCleared?: (values: { [key: string]: string }) => void;
  isRequestLoading?: boolean;
  initialInputValuesFromState?: any;
}

const deletEmptyFileds = (obj: any) => {
  let newObj = obj;
  for (let key in newObj) {
    if (newObj[key] === "") {
      delete newObj[key];
    }
  }

  return newObj;
};

const GlobalFilter: React.FC<GlobalFilterProps> = ({
  inputs,
  onSearchClick,
  onResetClick,
  onSelectCleared,
  isRequestLoading,
  initialInputValuesFromState,
}) => {
  const initialInputValues = inputs.reduce((acc, input) => {
    acc[input.key] = input.initialValue || "";
    return acc;
  }, {} as { [key: string]: string });

  const [inputValues, setInputValues] = useState<{ [key: string]: string }>(
    initialInputValues
  );

  const isSearchButtonDisabled =
    Object.values(inputValues).every((value) => !value) ||
    JSON.stringify(deletEmptyFileds(initialInputValuesFromState)) ===
      JSON.stringify(deletEmptyFileds(inputValues));

  const handleInputChange = (
    key: string,
    value: string | undefined,
    type: "text" | "select" | "date"
  ) => {
    const newValues = { ...inputValues, [key]: value || "" };
    setInputValues(newValues);

    if (value === undefined || value === "") {
      onSelectCleared?.(newValues);
    }
  };

  const renderInput = (input: InputConfig) => {
    switch (input.type) {
      case "text":
        return (
          <Input
            disabled={input.disabled}
            size="large"
            placeholder={input.placeholder}
            value={inputValues[input.key] || ""}
            allowClear
            onChange={(e) =>
              handleInputChange(input.key, e.target.value, "text")
            }
          />
        );
      case "select":
        return (
          <Select
            size="large"
            className="_input"
            placeholder={input.placeholder}
            value={inputValues[input.key] || undefined}
            allowClear
            disabled={input.disabled}
            onChange={(value) => handleInputChange(input.key, value, "select")}
            style={{
              minWidth: 250,
            }}
          >
            {input.options?.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        );
      case "date":
        return (
          <DatePicker
            className="_input"
            size="large"
            placeholder={input.placeholder}
            format={"DD-MM-YYYY"}
            allowClear
            disabled={input.disabled}
            value={
              inputValues[input.key]
                ? dayjs.unix(parseInt(inputValues[input.key]))
                : undefined
            }
            onChange={(date) => {
              handleInputChange(
                input.key,
                date ? date.unix().toString() : "",
                "date"
              );
            }}
            style={{ width: "100%" }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <GlobalFilterStyles>
      {inputs.map((input) => (
        <Space direction="vertical" size={8} key={input.key}>
          <span>{input.label}</span>
          {renderInput(input)}
        </Space>
      ))}

      {/* action buttons */}
      <Button
        icon={<DeleteOutlined />}
        size="large"
        onClick={() => {
          onResetClick({});
          setInputValues({}); // Reset to initialInputValues
        }}
        loading={isRequestLoading}
      >
        Clear filters
      </Button>
      <Button
        type="primary"
        size="large"
        onClick={() => onSearchClick(inputValues)}
        loading={isRequestLoading}
        disabled={isSearchButtonDisabled}
      >
        Search
      </Button>
    </GlobalFilterStyles>
  );
};

export default GlobalFilter;

const GlobalFilterStyles = styled.section`
  border: 1px solid rgba(144, 152, 178, 0.3);
  border-radius: 8px;
  transition: all 0.4s ease-in-out;
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  width: 100%;
  padding: 24px;
  position: relative;

  ._input {
    width: 100%;
    min-width: 250;
  }

  ::before {
    content: "";
    position: absolute;
    top: -10px;
    right: 2%;
    margin-left: -10px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid rgba(144, 152, 178, 0.3);
  }
`;
