import { useMutation, useQuery } from "react-query";
import { queryClient } from "src/App";
import { USERS } from "src/redux/actionTypes";
import { toastAction } from "src/redux/actions/actions";
import store from "src/redux/store";
import endpoints from "src/util/endpoints";
import { getRequest, putRequest } from "src/util/http";
import { parseEndpointParameters, sortByTimeDesc } from "src/util/util";

export const useUsersData = (filterQueryParams = "") => {
  const usersState = store.getState().users;

  const customEndpoint = `${endpoints.USERS}?${filterQueryParams}`;

  return useQuery(
    customEndpoint,
    () => getRequest(customEndpoint, "Failed to fetch users"),
    {
      refetchIntervalInBackground: true,
      keepPreviousData: true,
      onSuccess: (data) => {
        store.dispatch({
          type: USERS,
          payload: {
            ...usersState,
            users: sortByTimeDesc(data?.collections),
            total: data?.total || 0,
            queryKey: customEndpoint,
            filterQuery: filterQueryParams || "",
          },
        });
      },
    }
  );
};

export const useUpdateUsersData = () => {
  const endpoint = endpoints.HEALTH_CHECK;
  return useMutation(
    (values) =>
      putRequest(
        values,
        parseEndpointParameters(endpoint),
        "Failed to update maintenance data, Pls try again"
      ),
    {
      onSuccess: () => {
        toastAction({
          show: true,
          type: "success",
          timeout: 10000,
          message: "Maintenance data updated",
        });
        queryClient.invalidateQueries(endpoint);
      },
    }
  );
};
