import { Button } from "antd";
import { useState } from "react";
import styled from "styled-components";
import RateAndBal from "./component/quickremit/RateAndBal";

const methods = [
  {
    label: "SB Capital",
    description: (
      <span>
        Camerron Only (XAF).
        <br /> Mobile Money / Bank Transfer / Cash Pick-up.
      </span>
    ),
    buttonText: null,
  },
  {
    label: "Pivot Pay",
    description: (
      <span>
        Keya (KES), Uganda (UGX) & Tanzania (TZS).
        <br /> Bank Transfer & Mobile Money.
      </span>
    ),
    buttonText: null,
  },
  {
    label: "Quickremit",
    description: (
      <span>
        Nigeria (NGN).
        <br /> Bank Transfer Only.
      </span>
    ),
    buttonText: "View Rates & Balances",
  },
];

const PayoutMethods = () => {
  const [openQRrates, setOpenQRrates] = useState(false);

  const handleButtonClick = () => {
    setOpenQRrates(true);
  };

  return (
    <PayoutMethodsStyled>
      {/* Modals */}
      <RateAndBal open={openQRrates} onClose={() => setOpenQRrates(false)} />
      {/* Contents */}
      <ContentStyled>
        {methods.map((method, index) => (
          <MethodStyled key={index}>
            <h4>{method.label}</h4>
            <p>{method.description}</p>
            {method.buttonText && (
              <Button size="large" type="primary" onClick={handleButtonClick}>
                {method.buttonText}
              </Button>
            )}
          </MethodStyled>
        ))}
      </ContentStyled>
    </PayoutMethodsStyled>
  );
};

export default PayoutMethods;

const PayoutMethodsStyled = styled.div`
  margin: 32px 0px;
`;

const ContentStyled = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const MethodStyled = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  h4 {
    font-weight: 500;
    font-size: 20px;
    margin: 0 !important;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #707070;
  }

  Button {
    margin-top: 8px;
    width: fit-content;
  }
`;
