import { Tabs, TabsProps } from "antd";
import PaymentMethods from "./payment-methods/PaymentMethods";
import PayoutMethods from "./payout-methods/PayoutMethods";
import Rates from "./rates/Rates";

const Payments = () => {
  const items: TabsProps["items"] = [
    {
      key: "rates",
      label: "Rates",
      children: <Rates />,
    },
    {
      key: "payment_method",
      label: "Payment method",
      children: <PaymentMethods />,
      disabled: true,
    },
    {
      key: "payout_method",
      label: "Payout method",
      children: <PayoutMethods />,
    },
  ];

  const onChange = (key: string) => {
    console.log(key);
  };

  return <Tabs defaultActiveKey="1" items={items} onChange={onChange} />;
};

export default Payments;
