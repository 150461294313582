import axios, { AxiosResponse } from "axios";

import {
  APP_VALUES,
  AUTH,
  COMPETITOR_RATES,
  CONFIRM,
  EXCHANGE,
  EXCHANGES,
  EXCHANGE_RATES,
  EXCHANGE_SPREADS,
  HORIZONTAL_LOADING,
  LOADING,
  NOTIFICATIONS,
  PREORDERS,
  PROMOS,
  RECIPIENTS,
  REDIRECT,
  SIGN_IN,
  SIGN_UP,
  SUBMITTING,
  TOAST,
  TRANSFER,
  TRANSFER_DASHBOARD,
  UPLOAD_DOC,
  USERS,
  VERIFICATION_TYPE_STATUES,
} from "../actionTypes";
import config from "../../env";
import endpoints from "../../util/endpoints";
import store from "../store";
import { CookieService } from "../../services/CookieService";
import env from "../../env";
import { AppService } from "../../services/AppService";
import { paths } from "../../util/paths";
import {
  formatCurrency,
  genPaginationHashTable,
  getQueryParam,
  parseEndpointParameters,
  sortByTimeDesc,
} from "../../util/util";
import http from "../../util/http";
import constants from "../../util/constants";

const user = store.getState().auth.user;

export const checkAuth = () => {
  const session = CookieService.get(env.SESSION_KEY);
  const user = CookieService.get("user");
  const sessionId = CookieService.get(env.SESSION_ID);
  const serviceProvider = config.SERVICE_PROVIDER;

  if (session && user) {
    store.dispatch({
      type: AUTH,
      payload: { isAuthenticated: true, user: JSON.parse(user) },
    });
    return {
      isAuthenticated: true,
      user: JSON.parse(user),
      authToken: session,
      sessionId,
      serviceProvider,
    };
  } else {
    store.dispatch({
      type: AUTH,
      payload: { isAuthenticated: false, user: undefined },
    });
    return { isAuthenticated: false, user: undefined };
  }
};

export const createUserAccount = (
  data: any,
  callback?: Function,
  callbackOnError?: Function
) => {
  store.dispatch({ type: SUBMITTING, payload: SIGN_UP });
  http
    .post(config.API_HOST + endpoints.SIGN_UP, { ...data })
    .then((res: any) => {
      if (res.data.status === "200") {
        toastAction({
          show: true,
          type: "success",
          timeout: 60000,
          title: "User created!",
          message: `We have sent a verification mail to ${res.data.data.username}.`,
        });
        callback?.();
        // callback?.openModal?.(false);
        // // refresh the users
        // setTimeout(() => {
        //   callback?.();
        // }, 2500);
      } else {
        toastAction({
          show: true,
          type: "error",
          timeout: 20000,
          message: `${res.data.error.message}`,
        });
        callbackOnError?.();
      }
    })
    .catch((err) => {
      console.log(err);
    })
    .then(() => {
      store.dispatch({ type: SUBMITTING, payload: "" });
    });
};

export const createBusinessAccount = (data: any, callback?: any) => {
  store.dispatch({ type: SUBMITTING, payload: SIGN_UP });
  http
    .post(config.API_HOST + endpoints.BUSINESS_SIGN_UP, { ...data })
    .then((res: any) => {
      if (res.data.status === "200") {
        toastAction({
          show: true,
          type: "success",
          timeout: 60000,
          title: "Business created!",
          message: `We have sent a verification mail to ${res.data.data.username}.`,
        });
        callback?.openModal?.(false);
        callback();
      } else {
        toastAction({
          show: true,
          type: "error",
          timeout: 20000,
          message: `${res.data.error.message}`,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    })
    .then(() => {
      store.dispatch({ type: SUBMITTING, payload: "" });
    });
};

export const signInAction = (data: any) => {
  store.dispatch({ type: SUBMITTING, payload: SIGN_IN });
  axios
    .post(
      config.API_HOST + endpoints.SESSION,
      { ...data },
      {
        headers: { "X-SERVICE-PROVIDER": config.SERVICE_PROVIDER },
      }
    )
    .then((res: any) => {
      if (res.data.status === "200") {
        toastAction({
          show: true,
          type: "success",
          timeout: 5000,
          message: `Welcome, ${res.data.data.profile.firstName}`,
        });

        CookieService.put(env.SESSION_KEY, res.headers["x-auth-token"]);
        CookieService.put(env.SESSION_ID, res.headers["x-service-user-name"]);
        CookieService.put(
          "X-SERVICE_PROVIDER",
          res.headers["x-service-provider"]
        );
        axios
          .get(
            config.API_HOST +
              parseEndpointParameters(endpoints.USER, res.data.data.id)
          )
          .then((response) => {
            CookieService.put("user", JSON.stringify(response.data.data));
            store.dispatch({
              type: AUTH,
              payload: { isAuthenticated: true, user: response.data.data },
            });
          });
      } else {
        toastAction({
          show: true,
          type: "error",
          timeout: 10000,
          message: `${res.data.error.message}`,
        });
      }
    })
    .catch((err) => {
      console.log("sign in request error:", err);
    })
    .then(() => {
      store.dispatch({ type: SUBMITTING, payload: "" });
    });
};
export const signOut = (ignoreRequest = false) => {
  // debugger;
  if (!ignoreRequest) {
    store.dispatch({ type: LOADING, payload: true });
    http.delete(endpoints.SESSION).then((res) => {
      CookieService.remove(env.SESSION_KEY);
      CookieService.remove(env.SESSION_ID);
      store.dispatch({
        type: AUTH,
        payload: { isAuthenticated: false, user: undefined },
      });
      store.dispatch({ type: LOADING, payload: false });
    });
  } else {
    CookieService.remove(env.SESSION_KEY);
    CookieService.remove(env.SESSION_ID);
    store.dispatch({
      type: AUTH,
      payload: { isAuthenticated: false, user: undefined },
    });
    store.dispatch({ type: LOADING, payload: false });
  }
};

export const signOutAction = (
  ignoreRequest = false,
  isSuperSignout?: boolean
) => {
  sessionStorage.setItem("previousUrl", window.location.pathname);
  if (isSuperSignout) {
    return signOut(ignoreRequest);
  } else {
    confirmDialog({
      message: "Are you sure you want to logout your account?",
      isPositive: true,
      open: true,
      callback: () => signOut(ignoreRequest),
    });
  }
};

export const toastAction = (toastConfig: any) => {
  store.dispatch({ type: TOAST, payload: { ...toastConfig } });
};

export const appValuesAction = async () => {
  const allValues = await AppService.getValues();
  const countries = await AppService.getValueById(2);
  const services = await AppService.getServices();
  const agents = await AppService.getValueById(10);
  const values: any = {};
  values.values = allValues;
  values.countries = countries.data;
  values.services = services;
  values.agents = agents;

  store.dispatch({ type: APP_VALUES, payload: values });
};

export const editUserProfile = <T extends { profile: any }>(data: T) => {
  // store.dispatch({type: SUBMITTING, payload: paths.CHANGE_PASSWORD})
  http
    .put(parseEndpointParameters(endpoints.USER, user.id), { ...data })
    .then((res) => {
      if (res.data.status === "200") {
        toastAction({
          show: true,
          type: "success",
          timeout: 15000,
          message: `Profile updated`,
        });
      } else {
        toastAction({
          show: true,
          type: "error",
          timeout: 25000,
          message: res.data.error.message,
        });
      }
    });
};

export const changePasswordAction = (values: any) => {
  // store.dispatch({type: SUBMITTING, payload: paths.CHANGE_PASSWORD})
  const username = store.getState().auth.user.username;
  axios
    .post(
      config.API_HOST + endpoints.SESSION,
      {
        username,
        password: values.oldPassword,
      },
      {
        headers: { "X-SERVICE-PROVIDER": config.SERVICE_PROVIDER },
      }
    )
    .then((res: any) => {
      if (res.data.status === "200") {
        // axios.post(config.API_HOST + endpoints.PASSWORD_REQUEST,
        //     { username },
        //     { headers: {'X-SERVICE-PROVIDER': config.SERVICE_PROVIDER} })
        // .then(response=>{
        //     // ...
        // })
      } else {
        toastAction({
          show: true,
          type: "error",
          timeout: 10000,
          message: `Password is incorrect`,
        });
        store.dispatch({ type: SUBMITTING, payload: "" });
      }
    });
};

export const resetPasswordAction = (values: any, stage = "email") => {
  store.dispatch({ type: SUBMITTING, payload: paths.RESET_PASSWORD });

  if (stage === "email") {
    axios
      .post(
        config.API_HOST + endpoints.PASSWORD_REQUEST,
        { username: values.username },
        { headers: { "X-SERVICE-PROVIDER": config.SERVICE_PROVIDER } }
      )
      .then((res) => {
        if (res.status === 200) {
          toastAction({
            show: true,
            type: "info",
            timeout: 60000,
            title: `Now, check your mail`,
            message: `The password reset link has been sent to you at ${values.username}`,
          });
          store.dispatch({ type: SUBMITTING, payload: "" });
          store.dispatch({
            type: REDIRECT,
            payload: { ...store.getState().redirect, resetPassword: true },
          });
        } else {
          toastAction({
            show: true,
            type: "warning",
            timeout: 20000,
            message: `Could not send mail to ${values.username}`,
          });
          store.dispatch({ type: SUBMITTING, payload: "" });
        }
      });
  } else {
    axios
      .post(
        config.API_HOST + endpoints.PASSWORD_RESET,
        {
          password: values.password,
          confirmation: values.confirmation,
          token: getQueryParam("t"),
        },
        {
          headers: { "X-SERVICE-PROVIDER": config.SERVICE_PROVIDER },
        }
      )
      .then((res: any) => {
        if (res.data.status === "200") {
          toastAction({
            show: true,
            type: "success",
            timeout: 15000,
            message: `Password changed`,
          });
          store.dispatch({ type: SUBMITTING, payload: "" });
        } else {
          toastAction({
            show: true,
            type: "error",
            timeout: 10000,
            message: `Could not change password`,
          });
          store.dispatch({ type: SUBMITTING, payload: "" });
        }
      });
  }
};

export const resendTransfer = (transfer: any, callback: Function) => {
  confirmDialog({
    message: "Are you sure you want to resend this transfer?",
    isPositive: true,
    open: true,
    callback: () => confirmedResend(),
  });
  const confirmedResend = () => {
    store.dispatch({ type: LOADING, payload: true });
    const payload = {
      transferMethod: transfer.transferMethod,
      recipientId: transfer.recipientId,
      originCurrency: transfer.originCurrency,
      originAmount: transfer.originAmount,
      destinationCurrency: transfer.destinationCurrency,
      destinationAmount: transfer.destinationAmount,
    };
    const user = store.getState().auth.user;
    http
      .post(parseEndpointParameters(endpoints.CREATE_TRANSFER, user.id), {
        ...payload,
      })
      .then((res) => {
        if (res.data.status === "200") {
          toastAction({
            show: true,
            type: "success",
            timeout: 15000,
            message: "Transfer resent",
          });
          callback();
        } else {
          toastAction({
            show: true,
            type: "error",
            timeout: 15000,
            title: "Transfer failed",
            message: res.data.error.message,
          });
        }
        store.dispatch({ type: LOADING, payload: false });
      })
      .catch((err) => {
        store.dispatch({ type: LOADING, payload: false });
      })
      .then(() => {
        store.dispatch({ type: LOADING, payload: false });
      });
  };
};

export const getTransactionDetails = (callback?: Function, id?: string) => {
  const transferId = id ?? CookieService.get("transfer");
  if (!transferId) {
    callback?.();
    return toastAction({
      show: true,
      type: "info",
      timeout: 15000,
      message: `No transfer initiated yet`,
    });
  }

  store.dispatch({ type: LOADING, payload: true });
  const user = store.getState().auth.user;
  const transfer = store.getState().transfer;
  http
    .get(parseEndpointParameters(endpoints.GET_TRANSFER, user.id, transferId))
    .then((res) => {
      store.dispatch({
        type: TRANSFER,
        payload: { ...transfer, transactionDetails: { ...res.data.data } },
      });
      store.dispatch({ type: LOADING, payload: false });
    });
};

export const getUserTransactions = () => {
  const user = store.getState().auth.user;
  const transfer = store.getState().transfer;

  store.dispatch({ type: LOADING, payload: true });
  http
    .get(parseEndpointParameters(endpoints.TRANSFERS, user.id))
    .then((res) => {
      let transactions: any[] = res.data.data?.sort((a: any, b: any) => {
        if (a.dateCreated < b.dateCreated) {
          return 1;
        }
        if (a.dateCreated > b.dateCreated) {
          return -1;
        }
        return 0;
      });
      const paginatedTransactions = genPaginationHashTable(transactions, 10);
      const paginatedCancelledTransactions = genPaginationHashTable(
        transactions.filter((t) => t.status?.toLowerCase() === "cancelled"),
        10
      );
      const paginatedCompletedTransactions = genPaginationHashTable(
        transactions.filter((t) => t.status?.toLowerCase() === "complete"),
        10
      );
      const paginatedPendingTransactions = genPaginationHashTable(
        transactions.filter(
          (t) =>
            t.status?.toLowerCase() ===
            constants.TRANSFER_STATUS_PENDING.toLowerCase()
        ),
        10
      );
      store.dispatch({
        type: TRANSFER,
        payload: {
          ...transfer,
          transactions,
          paginatedTransactions,
          paginatedCompletedTransactions,
          paginatedCancelledTransactions,
          paginatedPendingTransactions,
        },
      });
    })
    .catch((err) => {
      console.log(err);
    })
    .then(() => {
      store.dispatch({ type: LOADING, payload: false });
    });
};

export const cancelTransfer = (callback: Function, id = null) => {
  confirmDialog({
    message: "Are you sure you want to cancel this transfer?",
    isPositive: false,
    open: true,
    callback: () => confirmedCancelTransfer(),
  });

  const confirmedCancelTransfer = () => {
    const transferId = id;
    if (!transferId) {
      callback?.();
      return toastAction({
        show: true,
        type: "info",
        timeout: 15000,
        message: `No transfer initiated yet`,
      });
    }

    store.dispatch({ type: LOADING, payload: true });
    const transfer = store.getState().transfer;
    const user = store.getState().auth.user;

    http
      .delete(parseEndpointParameters(endpoints.TRANSFER, transferId))
      .then((res) => {
        if (res.data.status === "200") {
          toastAction({
            show: true,
            type: "info",
            timeout: 10000,
            message: "Transfer has been cancelled",
          });
          store.dispatch({
            type: TRANSFER,
            payload: { ...transfer, transactionDetails: undefined },
          });
          store.dispatch({ type: LOADING, payload: false });

          callback();
        } else {
          store.dispatch({ type: LOADING, payload: false });
          toastAction({
            show: true,
            type: "error",
            timeout: 10000,
            message: res.data.error.message,
          });
        }
      });
  };
};

export const cancelPreorder = (callback: Function, id = null) => {
  confirmDialog({
    message: "Are you sure you want to cancel this preorder?",
    isPositive: false,
    open: true,
    callback: () => confirmedCancelPreorder(),
  });

  const confirmedCancelPreorder = () => {
    const transferId = id;
    if (!transferId) {
      callback?.();
      return toastAction({
        show: true,
        type: "info",
        timeout: 15000,
        message: `No preorder initiated yet`,
      });
    }

    store.dispatch({ type: LOADING, payload: true });

    http
      .delete(parseEndpointParameters(endpoints.PREORDER, transferId))
      .then((res) => {
        if (res.data.status === "200") {
          toastAction({
            show: true,
            type: "info",
            timeout: 10000,
            message: "Preorder has been cancelled",
          });
          store.dispatch({ type: LOADING, payload: false });

          callback();
        } else {
          store.dispatch({ type: LOADING, payload: false });
          toastAction({
            show: true,
            type: "error",
            timeout: 10000,
            message: res.data.error.message,
          });
        }
      });
  };
};

export const approveTransferDocumentation = (
  successCallback: Function,
  userId: string | number,
  accepted: boolean,
  errorCallback: Function
) => {
  confirmDialog({
    message: `Are you sure you want to ${
      accepted ? "approve" : "decline"
    } documentation?`,
    isPositive: true,
    open: true,
    field: accepted
      ? false
      : {
          title: "Reason:",
          placeholder: "Please state a reason",
          required: true,
        },
    callback: (fieldValue: string) => confirmedApproveDocs(fieldValue),
  });

  const confirmedApproveDocs = (reason: string) => {
    store.dispatch({ type: LOADING, payload: true });

    http
      .put(parseEndpointParameters(endpoints.DOCUMENTATION), {
        userId,
        documentation_accepted: accepted,
        reason,
      })
      .then((res) => {
        if (res.data.status === "200") {
          toastAction({
            show: true,
            type: "success",
            timeout: 10000,
            message: "Documentation has been approved",
          });
          store.dispatch({ type: LOADING, payload: false });

          successCallback();
        } else {
          store.dispatch({ type: LOADING, payload: false });
          toastAction({
            show: true,
            type: "error",
            timeout: 10000,
            message: res?.data?.error?.message,
          });
          errorCallback(res?.data?.error?.message);
        }
      });
  };
};

export const approveTransferVerification = (
  callback: Function,
  userId: string,
  verified: boolean
) => {
  confirmDialog({
    message: `Are you sure you want to ${
      verified ? "approve" : "decline"
    } transfer user verification? This action affects all user transfers.`,
    isPositive: true,
    open: true,
    callback: () => confirmedApproveVerification(),
  });

  const confirmedApproveVerification = () => {
    store.dispatch({ type: LOADING, payload: true });

    http
      .put(parseEndpointParameters(endpoints.VERIFICATION), {
        userId,
        verified,
      })
      .then((res) => {
        if (res.data.status === "200") {
          toastAction({
            show: true,
            type: "success",
            timeout: 10000,
            message: verified ? "User set verified!" : "User set unverified",
          });
          store.dispatch({ type: LOADING, payload: false });

          callback();
        } else {
          store.dispatch({ type: LOADING, payload: false });
          toastAction({
            show: true,
            type: "error",
            timeout: 10000,
            message: res.data.error.message,
          });
        }
      });
  };
};

export const updateFraudAlertTransfer = (
  callback: Function,
  transferId: string,
  approved: boolean
) => {
  confirmDialog({
    message: `Are you sure you want to ${
      approved ? "approve" : "block"
    } this transfer? ${
      approved ? "" : "This action affects all user transfers."
    }`,
    isPositive: true,
    open: true,
    callback: () => confirmedApproveVerification(),
  });

  const confirmedApproveVerification = () => {
    store.dispatch({ type: LOADING, payload: true });

    http
      .put(parseEndpointParameters(endpoints.TRANSFER, transferId), {
        status: constants.TRANSFER_STATUS_PAYMENT_COMPLETED,
        approved,
      })
      .then((res) => {
        if (res.data.status === "200") {
          toastAction({
            show: true,
            type: "success",
            timeout: 10000,
            message: approved
              ? "Transfer approved"
              : "Transfer blocked and user suspended",
          });
          store.dispatch({ type: LOADING, payload: false });

          callback();
        } else {
          store.dispatch({ type: LOADING, payload: false });
          toastAction({
            show: true,
            type: "error",
            timeout: 10000,
            message: res.data.error.message,
          });
        }
      });
  };
};

export const confirmUserPassword = (password: string, callback: Function) => {
  store.dispatch({ type: LOADING, payload: true });
  const user = store.getState().auth.user;
  const username = user.username;
  axios
    .post(
      config.API_HOST + endpoints.SESSION,
      { username, password, skipSession: true },
      {
        headers: { "X-SERVICE-PROVIDER": config.SERVICE_PROVIDER },
      }
    )
    .then((res: any) => {
      if (res.data.status === "200") {
        callback();
      } else {
        toastAction({
          show: true,
          type: "error",
          timeout: 10000,
          message: `${res.data.error.message}`,
        });
      }
    })
    .catch((err) => {})
    .then(() => {
      store.dispatch({ type: LOADING, payload: false });
    });
};

export const approveIDVerification = (
  callback: Function,
  userId: any,
  verified: boolean
) => {
  confirmDialog({
    message: `Please, input your account password to make this change`,
    isPositive: undefined,
    open: true,
    field: {
      title: "Password:",
      placeholder: "Your account password here...",
      required: true,
    },
    callback: (fieldValue: string) =>
      confirmUserPassword(fieldValue, confirmedApproveVerification),
  });

  const confirmedApproveVerification = () => {
    store.dispatch({ type: LOADING, payload: true });

    http
      .put(parseEndpointParameters(endpoints.VERIFICATION), {
        userId,
        verified,
      })
      .then((res) => {
        if (res.data.status === "200") {
          toastAction({
            show: true,
            type: "success",
            timeout: 10000,
            message: verified ? "User set verified!" : "User set unverified",
          });
          store.dispatch({ type: LOADING, payload: false });

          callback();
        } else {
          store.dispatch({ type: LOADING, payload: false });
          toastAction({
            show: true,
            type: "error",
            timeout: 10000,
            message: res.data.error.message,
          });
        }
      });
  };
};

export const getVerificationTypeAndStatus = () => {
  http.get(endpoints.UPDATE_VERIFICATION).then((res) => {
    if (res.data.status === "200") {
      store.dispatch({
        type: VERIFICATION_TYPE_STATUES,
        payload: {
          status: res.data.data.statuses,
          type: res.data.data.types,
        },
      });
    } else {
      toastAction({
        show: true,
        type: "error",
        timeout: 10000,
        message: res.data.error.message,
      });
    }
  });
};

export const deleteUser = (callback: Function, user: any) => {
  confirmDialog({
    message: `Please, input your account password to make this change`,
    isPositive: undefined,
    open: true,
    field: {
      title: "Password:",
      placeholder: "Your account password here...",
      required: true,
    },
    callback: (fieldValue: string) =>
      confirmUserPassword(fieldValue, confirmedDeletedUser),
  });

  const confirmedDeletedUser = () => {
    store.dispatch({ type: LOADING, payload: true });

    http
      .delete(parseEndpointParameters(endpoints.DELETE_USER, user.id))
      .then((res) => {
        if (res.data.status === "200") {
          toastAction({
            show: true,
            type: "success",
            timeout: 10000,
            message: `user with email ${user.username} is deleted successfully`,
          });
          store.dispatch({ type: LOADING, payload: false });

          callback();
        } else {
          store.dispatch({ type: LOADING, payload: false });
          toastAction({
            show: true,
            type: "error",
            timeout: 10000,
            message: res.data.error.message,
          });
        }
      })
      .catch((err) => {
        console.log("err------>>>>>", err);
      });
  };
};

export const approveTransfer = (callback: Function, id: number | string) => {
  confirmDialog({
    message: "Are you sure you want to approve this transfer?",
    isPositive: true,
    open: true,
    callback: () => confirmedApproveTransfer(),
  });

  const confirmedApproveTransfer = () => {
    const transferId: any = id;

    store.dispatch({ type: LOADING, payload: true });
    const transfer = store.getState().transfer;
    const user = store.getState().auth.user;

    http
      .put(parseEndpointParameters(endpoints.TRANSFER, transferId), {
        approved: 1,
      })
      .then((res) => {
        if (res.data.status === "200") {
          toastAction({
            show: true,
            type: "success",
            timeout: 10000,
            message: "Transfer has been approved",
          });
          store.dispatch({
            type: TRANSFER,
            payload: { ...transfer, transactionDetails: undefined },
          });
          store.dispatch({ type: LOADING, payload: false });

          callback();
        } else {
          store.dispatch({ type: LOADING, payload: false });
          toastAction({
            show: true,
            type: "error",
            timeout: 10000,
            message: res.data.error.message,
          });
        }
      });
  };
};
export const remitFundsWithPivot = (
  callback: Function,
  id: number | string
) => {
  confirmDialog({
    message:
      "Are you sure you want to send the destination amount of this transfer to the designated recipient?",
    isPositive: true,
    open: true,
    callback: () => confirmedRemitTransferFunds(),
  });

  const confirmedRemitTransferFunds = () => {
    const transferId: any = id;

    store.dispatch({ type: LOADING, payload: true });
    const transfer = store.getState().transfer;
    const user = store.getState().auth.user;

    http
      .post(parseEndpointParameters(endpoints.PIVOT_PAYMENT), {
        transferId,
        transactionNarration: "",
      })
      .then((res) => {
        console.log(res);
        if (res?.data?.data?.status === "SUCCESS") {
          toastAction({
            show: true,
            type: "success",
            timeout: 10000,
            message: "Funds remitted to recipient",
          });
          store.dispatch({
            type: TRANSFER,
            payload: { ...transfer, transactionDetails: undefined },
          });
          store.dispatch({ type: LOADING, payload: false });

          callback();
        } else {
          let parsedBody;
          try {
            const response = JSON.stringify(res?.data?.data?.data);
            console.log(response);
            const parsedResponse = JSON.parse(response);
            console.log(parsedResponse);
            const body = JSON.parse(parsedResponse).body;
            console.log(body);
            parsedBody = JSON.parse(body);
            console.log(parsedBody);
          } catch (error) {}

          store.dispatch({ type: LOADING, payload: false });
          toastAction({
            show: true,
            type: "error",
            timeout: 10000,
            message: res?.data?.error?.message || parsedBody?.responsefield15,
          });
        }
      });
  };
};

export const completeTransfer = (callback: Function, id: number | string) => {
  confirmDialog({
    message: "Are you sure you want to manually complete this transfer?",
    isPositive: true,
    open: true,
    callback: () => confirmedCompleteTransfer(),
  });

  const confirmedCompleteTransfer = () => {
    const transferId: any = id;

    store.dispatch({ type: LOADING, payload: true });
    const transfer = store.getState().transfer;
    const user = store.getState().auth.user;

    http
      .put(parseEndpointParameters(endpoints.TRANSFER, transferId), {
        status: "COMPLETE",
      })
      .then((res) => {
        if (res.data.status === "200") {
          toastAction({
            show: true,
            type: "success",
            timeout: 10000,
            message: "Transfer has been set completed",
          });
          store.dispatch({
            type: TRANSFER,
            payload: { ...transfer, transactionDetails: undefined },
          });
          store.dispatch({ type: LOADING, payload: false });

          callback();
        } else {
          store.dispatch({ type: LOADING, payload: false });
          toastAction({
            show: true,
            type: "error",
            timeout: 10000,
            message: res.data.error.message,
          });
        }
      });
  };
};

export const setTransferToPaymentComplete = (
  callback: Function,
  id: number | string
) => {
  confirmDialog({
    message: "Are you sure you want to set this transfer to payment completed?",
    isPositive: false,
    open: true,
    callback: () => confirmedPaymentCompleteTransfer(),
  });

  const confirmedPaymentCompleteTransfer = () => {
    const transferId: any = id;

    store.dispatch({ type: LOADING, payload: true });

    http
      .put(parseEndpointParameters(endpoints.TRANSFER, transferId), {
        status: constants.TRANSFER_STATUS_PAYMENT_COMPLETED,
      })
      .then((res) => {
        if (res.data.status === "200") {
          toastAction({
            show: true,
            type: "success",
            timeout: 10000,
            message: "Transfer has been set PAYMENT COMPLETED",
          });
          store.dispatch({ type: LOADING, payload: false });

          callback();
        } else {
          store.dispatch({ type: LOADING, payload: false });
          toastAction({
            show: true,
            type: "error",
            timeout: 10000,
            message: res.data.error.message,
          });
        }
      });
  };
};

export const rejectTransfer = (callback: Function, id: number | string) => {
  confirmDialog({
    message: "Are you sure you want to reject this transfer?",
    isPositive: false,
    open: true,
    field: {
      title: "Reason:",
      placeholder: "Please state a reason",
      required: true,
    },
    callback: (fieldValue: string) => confirmedRejectTransfer(fieldValue),
  });

  const confirmedRejectTransfer = (reason: string) => {
    const transferId: any = id;

    store.dispatch({ type: LOADING, payload: true });

    http
      .put(parseEndpointParameters(endpoints.TRANSFER, transferId), {
        status: constants.TRANSFER_STATUS_REJECTED,
        reason,
      })
      .then((res) => {
        if (res.data.status === "200") {
          toastAction({
            show: true,
            type: "success",
            timeout: 10000,
            message: "Transfer has been rejected",
          });
          store.dispatch({ type: LOADING, payload: false });

          callback();
        } else {
          store.dispatch({ type: LOADING, payload: false });
          toastAction({
            show: true,
            type: "error",
            timeout: 10000,
            message: res.data.error.message,
          });
        }
      });
  };
};

export const markRejectedTransferRefunded = (
  callback: Function,
  id: number | string
) => {
  confirmDialog({
    message: "Are you sure you want to set this transfer refunded?",
    isPositive: true,
    open: true,
    callback: () => confirmedRefundTransfer(),
  });

  const confirmedRefundTransfer = () => {
    const transferId: any = id;

    store.dispatch({ type: LOADING, payload: true });

    http
      .put(parseEndpointParameters(endpoints.TRANSFER, transferId), {
        status: constants.TRANSFER_STATUS_REFUNDED,
      })
      .then((res) => {
        if (res.data.status === "200") {
          toastAction({
            show: true,
            type: "success",
            timeout: 10000,
            message: "Transfer has been set refunded",
          });
          store.dispatch({ type: LOADING, payload: false });

          callback();
        } else {
          store.dispatch({ type: LOADING, payload: false });
          toastAction({
            show: true,
            type: "error",
            timeout: 10000,
            message: res.data.error.message,
          });
        }
      });
  };
};

export const approvePreorder = (callback: Function, id: number | string) => {
  confirmDialog({
    message: "Are you sure you want to approve this preorder?",
    isPositive: true,
    open: true,
    callback: () => confirmedApprovePreorder(),
  });

  const confirmedApprovePreorder = () => {
    const transferId: any = id;

    store.dispatch({ type: LOADING, payload: true });

    http
      .put(parseEndpointParameters(endpoints.PREORDER, transferId), {
        status: "complete",
      })
      .then((res) => {
        if (res.data.status === "200") {
          toastAction({
            show: true,
            type: "success",
            timeout: 10000,
            message: "Preorder has been approved",
          });
          store.dispatch({ type: LOADING, payload: false });

          callback();
        } else {
          store.dispatch({ type: LOADING, payload: false });
          toastAction({
            show: true,
            type: "error",
            timeout: 10000,
            message: res.data.error.message,
          });
        }
      });
  };
};

export const confirmDialog = (data: {
  title?: string;
  message: string;
  isPositive?: boolean;
  open: boolean;
  callback: Function;
  field?: any;
}) => {
  const title = data.title || "Confirm action";
  store.dispatch({
    type: CONFIRM,
    payload: {
      message: data.message,
      isPositive: data.isPositive ?? false,
      open: data.open,
      field: data.field,
      callback: data.callback,
      title: title,
    },
  });
};

export const uploadUserDocument = (data: {
  open: boolean;
  user: any;
  transfer: any;
  callback?: Function;
}) => {
  store.dispatch({
    type: UPLOAD_DOC,
    payload: {
      open: data.open,
      user: data.user,
      transfer: data.transfer,
      callback: data.callback,
    },
  });
};

export const getTransfersDashboard = () => {
  http
    .get(`${endpoints.TRANSFERS}?limit=100&days=1`)
    .then((res: any) => {
      if (res.data.status === "200") {
        store.dispatch({
          type: TRANSFER_DASHBOARD,
          payload: {
            transfers: res.data.data.collections,
          },
        });
        store.dispatch({ type: LOADING, payload: false });
      }
    })
    .catch((err: any) => console.log(err))
    .then(() => {
      store.dispatch({ type: LOADING, payload: false });
    });
};

export const getTransfers = () => {
  store.dispatch({ type: HORIZONTAL_LOADING, payload: true });
  const transferState = store.getState().transfer;
  const limit = transferState.limit;
  const offset = transferState.offset;
  const days = transferState.days;
  const status = transferState.status;
  const search = transferState.search;

  //--Tthis encode the email address to include it as a query parameter
  const formatSearchValue = encodeURIComponent(search);

  //console.log(transferState);

  http
    .get(
      status === "ALL"
        ? `${endpoints.TRANSFERS}?days=${days}&limit=${limit}&offset=${offset}&transactionId=${formatSearchValue}`
        : `${endpoints.TRANSFERS}?days=${days}&limit=${limit}&offset=${offset}&transactionId=${formatSearchValue}&status=${status}`
    )
    .then((res) => {
      if (res.data.status === "200")
        store.dispatch({
          type: TRANSFER,
          payload: {
            ...transferState,
            transfers: sortByTimeDesc(res.data.data.collections),
            total: res.data.data.total,
          },
        });
      store.dispatch({ type: HORIZONTAL_LOADING, payload: false });
      store.dispatch({ type: LOADING, payload: false });
    })
    .catch((err) => {
      toastAction({
        show: true,
        type: "error",
        timeout: 25000,
        message: err.message,
      });
      console.log(err);
    })
    .then(() => {
      store.dispatch({ type: LOADING, payload: false });
    });
};

export const getTransferData = (userId: number) => {
  return new Promise((resolve, reject) => {
    http
      .get(`/transfer/${userId}`)

      .then((res) => {
        if (res.data.status === "200") {
          resolve(res.data.data); // Use 'return' instead of 'resolve'
        } else {
          console.log(res.data.error, userId);
          reject(new Error("Error occurred while fetching transfers"));
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getRecipient = (recipientId: string | number) => {
  const recipients = store.getState().recipients.recipients ?? [];
  return recipients?.find((recipient: any) => recipient.id === recipientId);
};

export const getTransfersPaginated = (
  limit: number,
  offset: number,
  callback: Function
) => {
  // store.dispatch({type: LOADING, payload: true})
  const transferState = store.getState().transfer;

  http
    .get(endpoints.TRANSFERS + `?limit=${limit}&offset=${offset}&order=id DESC`)
    .then((res) => {
      if (res.data.status === "200")
        store.dispatch({
          type: TRANSFER,
          payload: {
            ...transferState,
            transfers: sortByTimeDesc(res.data.data),
          },
        });
      store.dispatch({ type: LOADING, payload: false });
      callback();
    })
    .catch((err) => console.log(err))
    .then(() => {
      store.dispatch({ type: LOADING, payload: false });
    });
};

export const searchTransfers = (
  days: number,
  searchValue: string
  // status: string
) => {
  const encodedSearchValue = searchValue.replace(/\s+/g, "&");
  store.dispatch({ type: HORIZONTAL_LOADING, payload: true });
  http
    .get(
      `${endpoints.TRANSFERS}?limit=800&days=${days}&transactionId=${encodedSearchValue}`
    )
    .then((res) => {
      if (res.data.status === "200")
        store.dispatch({
          type: TRANSFER,
          payload: {
            transfers: sortByTimeDesc(res.data.data.collections),
            total: res.data.data.total,
          },
        });
      store.dispatch({ type: HORIZONTAL_LOADING, payload: false });
    })
    .catch((err) => console.log(err))
    .then(() => {
      store.dispatch({ type: HORIZONTAL_LOADING, payload: false });
    });
};

export const UpdateUserVerifications = (
  userId: string,
  data: any,
  callback?: Function,
  callbackOnError?: Function
) => {
  http
    .put(endpoints.UPDATE_VERIFICATION, {
      userId: userId,
      verificationType: data.verificationType,
      status: data.verificationStatus,
      reason: data.reason,
    })
    .then((res) => {
      if (res.data.status === "200") {
        toastAction({
          show: true,
          type: "success",
          timeout: 15000,
          message: `User Verification updated successfully`,
        });
        callback?.();
      } else {
        toastAction({
          show: true,
          type: "error",
          timeout: 25000,
          message: res.data.error.message,
        });
        callbackOnError?.();
      }
    });
};

export const VerifyUserByEmail = (
  userID: any,
  meta: any,
  profile: any,
  callback?: Function
) => {
  // store.dispatch({type: SUBMITTING, payload: paths.CHANGE_PASSWORD})
  const fieldName = "confirmed";
  const verifiedValue = "1";

  confirmDialog({
    message:
      "Are you sure you want to verify this user's Email or phone number?",
    isPositive: false,
    open: true,
    callback: () => veryUser(),
  });

  const veryUser = () => {
    if (fieldName in meta) {
      meta[fieldName] = verifiedValue;

      http
        .put(`/user/${userID}`, {
          meta: meta,
          profile: profile,
        })
        .then((res) => {
          if (res.data.status === "200") {
            toastAction({
              show: true,
              type: "success",
              timeout: 15000,
              title: "Verified",
              message: `User email successfully verified`,
            });
          } else {
            toastAction({
              show: true,
              type: "error",
              timeout: 25000,
              message: res.data.error.message,
            });
          }
          callback?.();
        });
    } else {
      toastAction({
        show: true,
        type: "error",
        timeout: 25000,
        message: "user's email or phone number cannot be verified",
      });
    }
  };
};

export const getUserData = (userId: number, callback: Function) => {
  return new Promise((resolve, reject) => {
    http
      .get(`/user/${userId}`)
      .then((res) => {
        if (res.data.status === "200") {
          callback(res.data.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getUsers = () => {
  const usersState = store.getState().users;
  const limit = usersState.limit;
  const offset = usersState.offset;
  const days = usersState.days;
  const status = usersState.status;
  const search = usersState.search;

  store.dispatch({ type: HORIZONTAL_LOADING, payload: true });
  //--Tthis encode the email address to include it as a query parameter
  const formatSearchValue = encodeURIComponent(search);

  http
    .get(
      status === "ALL"
        ? `${endpoints.USERS}?days=${days}&limit=${limit}&offset=${offset}&search=${formatSearchValue}`
        : `${endpoints.USERS}?days=${days}&limit=${limit}&offset=${offset}&search=${formatSearchValue}&status=${status}`
    )
    .then((res) => {
      if (res.data.status === "200")
        store.dispatch({
          type: USERS,
          payload: {
            ...usersState,
            users: sortByTimeDesc(res.data.data.collections),
            total: res.data.data.total,
          },
        });
      store.dispatch({ type: HORIZONTAL_LOADING, payload: false });
    })
    .catch((err) => console.log(err))
    .then(() => {
      store.dispatch({ type: HORIZONTAL_LOADING, payload: false });
    });
};

export const getUserLogs = (userId: string, setUserLogs?: Function) => {
  http
    .get(parseEndpointParameters(endpoints.USER_LOGS, userId))
    .then((res) => {
      if (res.data.status === "200") {
        //setUserLogs(res.data.data);
        console.log(res.data.data);
      }
    })
    .catch((err) => console.log(err))
    .then(() => {
      store.dispatch({ type: LOADING, payload: false });
    });
};

export const getUser = (userId: string | number) => {
  const users = store.getState().users?.users ?? [];
  return users?.find((user: any) => user.id == userId);
};

export const getRecipients = (userId: any) => {
  http
    .get(parseEndpointParameters(endpoints.RECIPIENTS, userId))
    .then((res: any) => {
      if (res.data.status === "200") {
        store.dispatch({ type: RECIPIENTS, payload: res.data.data });
      } else {
      }
    })
    .catch((err) => {
      console.log(err);
    })
    .then(() => {});
};

export const getServiceRate = (
  transferMethod = "",
  getRecipientsValue = false
) => {
  const transfer = store.getState().transfer;
  if (!transfer.allowOperatorFee) {
    store.dispatch({ type: TRANSFER, payload: { ...transfer, serviceFee: 0 } });
    return 0;
  }
  const transferMethodsIds: any = {
    mobile_money: "1",
    bank_transfer: "2",
    cash_pickup: "3",
  };
  const services = store.getState().appValues.services;
  const service =
    services?.data?.filter(
      (s: any) =>
        s.id === transferMethodsIds[transferMethod || transfer.transferMethod]
    )[0] || services?.data?.[0];
  const fees =
    service?.fees?.filter(
      (f: any) =>
        Number(f.lowerLimit) <= Number(transfer.toReceive.value) &&
        Number(f.upperLimit) >= Number(transfer.toReceive.value)
    )[0] || service?.fees?.[0];

  const equiFee =
    fees?.type === "PERCENTAGE"
      ? (Number(fees?.fee) * transfer.toReceive?.value) / 100
      : Number(fees?.fee);

  const mobileMoneyTax = Number((0.2 * (transfer.toReceive?.value || 0)) / 100);

  const serviceFee =
    (!transferMethod && transfer.transferMethod === "mobile_money") ||
    (transferMethod && transferMethod === "mobile_money")
      ? Number(
          (
            (Number(equiFee) + Number(mobileMoneyTax)) /
            transfer.conversionRate?.rate
          ).toFixed(2)
        )
      : Number(equiFee);

  store.dispatch({
    type: TRANSFER,
    payload: { ...transfer, serviceFee: Number(serviceFee) },
  });
  return Number(serviceFee) || 0;
};

export const getServiceRateValue = (
  toReceiveValue: string | number,
  transferMethod: string,
  getRecipientsValue = false,
  checkOperatorFee = true
) => {
  const transfer = store.getState().transfer;
  if (!transfer.allowOperatorFee && checkOperatorFee) return 0;

  const transferMethodsIds: any = {
    mobile_money: "1",
    bank_transfer: "2",
    cash_pickup: "3",
  };
  const services = store.getState().appValues.services;
  const service =
    services?.data?.filter(
      (s: any) => s.id === transferMethodsIds[transferMethod]
    )[0] || services?.data?.[0];
  const fees =
    service?.fees?.filter(
      (f: any) =>
        Number(f.lowerLimit) <= Number(toReceiveValue) &&
        Number(f.upperLimit) >= Number(toReceiveValue)
    )[0] || service?.fees?.[0];

  const equiFee =
    fees?.type === "PERCENTAGE"
      ? (Number(fees?.fee) * Number(toReceiveValue)) / 100
      : Number(fees?.fee);

  const mobileMoneyTax = Number((0.2 * Number(toReceiveValue)) / 100);

  if (getRecipientsValue) {
    if (transferMethod && transferMethod === "mobile_money") {
      return Number(equiFee + mobileMoneyTax);
    } else {
      return Number((equiFee * transfer.conversionRate?.rate).toFixed(2));
    }
  }

  const serviceFee =
    transferMethod && transferMethod === "mobile_money"
      ? (
          (Number(equiFee) + Number(mobileMoneyTax)) /
          transfer.conversionRate?.rate
        ).toFixed(2)
      : equiFee;
  return Number(serviceFee) || 0;
};

export const initiatePayment = (callback?: Function, meta = {}, data = {}) => {
  store.dispatch({ type: LOADING, payload: true });

  const transfer = store.getState().transfer;
  const userId = store.getState().auth.user.id;
  console.log(transfer);

  const payload = {
    transferId: transfer.transactionDetails.id,
    method: transfer.paymentMethod,
    amount: transfer.transactionDetails.originAmount,
    reference: `${transfer.transactionDetails.originAmount}`,
    status: constants.TRANSFER_STATUS_PENDING,
    dateCreated: Math.round(Date.now() / 1000),
    lastUpdated: null,
    meta,
    data,
  };

  http
    .post(parseEndpointParameters(endpoints.INITIATE_PAYMENT, userId), payload)
    .then((res) => {
      if (res.data.id) {
        callback?.();
        store.dispatch({ type: LOADING, payload: false });
      } else {
        toastAction({
          show: true,
          type: "error",
          timeout: 10000,
          message: res.data.error.message,
        });
        store.dispatch({ type: LOADING, payload: false });
      }
    })
    .catch()
    .then(() => {
      store.dispatch({ type: LOADING, payload: false });
    });
};

export const getQuoteService = ($_1: string, $_2: string) => {
  store.dispatch({ type: LOADING, payload: true });
  const quoteId = CookieService.get("QUOTE");

  if (quoteId) {
    http
      .get(parseEndpointParameters(endpoints.GET_QUOTE, quoteId))
      .then((res) => {
        const transfer = store.getState().transfer;
        if (res.data.status === "200") {
          store.dispatch({
            type: TRANSFER,
            payload: {
              ...transfer,
              conversionRate: {
                ...res.data.data,
                rate: res.data.data.rate?.rate,
              },
            },
          });
          store.dispatch({ type: LOADING, payload: false });
        } else {
          getNewQuote($_1, $_2);
        }
      })
      .catch(() => {
        getNewQuote($_1, $_2);
      })
      .then(() => {
        store.dispatch({ type: LOADING, payload: false });
      });
  } else {
    getNewQuote($_1, $_2);
  }
};

// export const getNewQuote = ($_1: string, $_2: string) => {
//     const transfer = store.getState().transfer
//     axios.get(config.API_HOST + parseEndpointParameters(endpoints.QUOTE_SERVICE, $_1, $_2 ))
//     .then(res => {
//         if(res.data.status === "200"){
//             store.dispatch({type: TRANSFER, payload: {...transfer, conversionRate: {...res.data.data}}})
//             store.dispatch({type: LOADING, payload: false})
//         }
//     }).catch(()=>{
//         store.dispatch({type: LOADING, payload: false})
//     })
//     .then(()=>{
//         store.dispatch({type: LOADING, payload: false})
//     })
// }

export const getNewQuote = ($_1?: string, $_2?: string) => {
  store.dispatch({ type: LOADING, payload: true });
  const transfer = store.getState().transfer;
  $_1 = $_1 ?? transfer.toSend.currency;
  $_2 = $_2 ?? transfer.toReceive.currency;
  axios
    .get(
      config.API_HOST +
        parseEndpointParameters(endpoints.QUOTE_SERVICE, $_1, $_2)
    )
    .then((res) => {
      if (res.data.status === "200") {
        const data = res.data.data;
        if (data?.base?.toUpperCase() === "EUR") {
          data.rate = 655.96;
        }
        store.dispatch({
          type: TRANSFER,
          payload: { ...transfer, conversionRate: { ...data } },
        });
        store.dispatch({ type: LOADING, payload: false });
      }
    })
    .catch(() => {
      store.dispatch({ type: LOADING, payload: false });
    })
    .catch(() => {
      store.dispatch({ type: LOADING, payload: false });
    })
    .then(() => {
      store.dispatch({ type: LOADING, payload: false });
    });
};

export const setNewQuote = (base: string, target: string) => {
  const payload: { base: string; target: string; meta?: any } = {
    base,
    target,
  };
  const userId = store.getState().auth.user?.id;
  if (userId) payload.meta = { userId };
  http
    .post("/quote", payload)
    .then((res) => {
      if (res.data.status === "200") {
        CookieService.put("QUOTE", res.data.data.id);
      } else {
        toastAction({
          show: true,
          type: "warning",
          timeout: 10000,
          message: res.data.error.message,
        });
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

// export const getExchangeRates = (currencyFrom: string, currencyTo: string) => {
//     axios.get(env.API_HOST + parseEndpointParameters(endpoints.EXCHANGE, currencyFrom, currencyTo))
//     .then((res: any) => {
//         if (res.status === 200)  {
//             const newPayload: any = {};
//             newPayload[currencyFrom.toLowerCase()] = res.data?.data;
//             store.dispatch({type: EXCHANGE, payload: {...store.getState().exchange, ...newPayload }})
//         }
//     })
// }

export const confirmTransfer = (
  recipient: any,
  transfer: any,
  callback: Function
) => {
  store.dispatch({ type: LOADING, payload: true });
  const user = store.getState().auth.user;
  const payload = {
    transferMethod: transfer.transferMethod,
    recipientId: recipient.id,
    originCurrency: transfer.toSend?.currency,
    originAmount: Number(transfer.toSend?.adjusted ?? transfer.toSend?.value),
    destinationCurrency: transfer.toReceive?.currency,
    destinationAmount: Number(transfer.toReceive?.total),
    paymentMethod: {},
    promo: transfer.promo?.code,
    referralDiscountValue: transfer?.referralDiscount?.value,
    meta: {
      byAdmin: true,
      adminId: user.id,
      serviceFee: transfer.serviceFee,
      exchangeBase: transfer.conversionRate?.base,
      exchangeRate: formatCurrency(transfer.conversionRate?.rate),
      exchangeTarget: transfer.conversionRate?.target,
      totalToPay: formatCurrency(`${Number(transfer.toSend.total)}`),
    },
  };
  const transferUserId = getQueryParam("user");
  http
    .post(parseEndpointParameters(endpoints.CREATE_TRANSFER, transferUserId), {
      ...payload,
    })
    .then((res) => {
      if (res.data.status === "200") {
        callback();
        CookieService.put("transfer", JSON.stringify(res.data.data.id));
        getTransactionDetails(callback);
      } else {
        toastAction({
          show: true,
          type: "error",
          timeout: 15000,
          title: "Transfer failed",
          message: res.data.error.message,
        });
      }
      store.dispatch({ type: LOADING, payload: false });
    })
    .catch((err) => {
      store.dispatch({ type: LOADING, payload: false });
    })
    .then(() => {
      store.dispatch({ type: LOADING, payload: false });
    });
};

export const createRecipient = (recipientData: any, callback?: any) => {
  recipientData = {
    firstName: recipientData.firstName,
    lastName: recipientData.lastName,
    profile: {
      ...recipientData,
    },
  };
  store.dispatch({ type: SUBMITTING, payload: paths.RECIPIENT });
  const userId = getQueryParam("user");
  http
    .post(parseEndpointParameters(endpoints.CREATE_RECIPIENT, userId), {
      ...recipientData,
    })
    .then((res: any) => {
      if (res.data.status === "200") {
        getRecipients(userId);
        toastAction({
          show: true,
          type: "success",
          timeout: 10000,
          message: "New recipient added",
        });
        callback?.openModal?.(false);
        callback?.selectRecipient?.(res.data.data);
      } else {
        toastAction({
          show: true,
          type: "error",
          timeout: 15000,
          title: "Add recipient failed",
          message: res.data.error.message,
        });
      }
    })
    .catch((err) => console.log(err))
    .then(() => {
      store.dispatch({ type: SUBMITTING, payload: "" });
    });
};

export const getPreorders = () => {
  store.dispatch({ type: LOADING, payload: true });
  const preordersState = store.getState().preorders;

  http
    .get(endpoints.PREORDERS)
    .then((res) => {
      if (res.data.status === "200") {
        console.log(res.data.data);
        store.dispatch({
          type: PREORDERS,
          payload: {
            ...preordersState,
            preorders: sortByTimeDesc(res.data.data),
          },
        });
        store.dispatch({ type: LOADING, payload: false });
      }
    })
    .catch((err) => console.log(err))
    .then(() => {
      store.dispatch({ type: LOADING, payload: false });
    });
};

export const getAllExchangeRates = () => {
  axios
    .get(env.API_HOST + parseEndpointParameters(endpoints.EXCHANGES))
    .then((res: any) => {
      if (res.status === 200) {
        const eurToXaf = res.data?.data?.filter(
          (xchange: any) => xchange.base === "EUR" && xchange.target === "XAF"
        );
        eurToXaf[0].rate = "655.96";
        store.dispatch({ type: EXCHANGES, payload: [...res.data?.data] });
      }
    })
    .catch((err) => console.log(err))
    .then(() => {
      store.dispatch({ type: LOADING, payload: false });
    });
};

export const createUserSideEffect = (data: any, callback?: any) => {
  store.dispatch({ type: LOADING, payload: true });
  axios
    .post(
      config.API_HOST + endpoints.SIGN_UP,
      { ...data },
      {
        headers: { "X-SERVICE-PROVIDER": config.SERVICE_PROVIDER },
      }
    )
    .then((res: any) => {
      let userId;
      if (res.data.status === "200") {
        toastAction({
          show: true,
          type: "success",
          timeout: 60000,
          title: "User created!",
          message: `We have sent a verification mail to ${res.data.data.username}.`,
        });

        userId = res.data.data.id;
      } else {
        const users = store.getState()?.users?.users;
        userId = users.filter((user: any) => user.username === data.username)[0]
          .id;
      }
      const newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?user=" +
        userId;
      window.history.pushState({ path: newurl }, "", newurl);
      callback();
    })
    .catch((err) => {
      console.log(err);
    })
    .then(() => {
      store.dispatch({ type: LOADING, payload: false });
    });
};

export const createUpdatePromo = (
  data: any,
  isUpdate: boolean,
  callback?: any
) => {
  store.dispatch({ type: SUBMITTING, payload: PROMOS });
  console.log(isUpdate);

  (isUpdate
    ? http.put(endpoints.PROMO, { ...data })
    : http.post(endpoints.PROMO, { ...data })
  )
    .then((res: any) => {
      if (res.data.status === "200") {
        toastAction({
          show: true,
          type: "success",
          timeout: 60000,
          message: `Promo ${isUpdate ? "updated" : "created"}!`,
        });
        callback?.();
      } else {
        toastAction({
          show: true,
          type: "error",
          timeout: 20000,
          message: `${res.data.error.message}`,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    })
    .then(() => {
      store.dispatch({ type: SUBMITTING, payload: "" });
    });
};

export const getPromos = (requestType: string) => {
  const promosState = store.getState().promos;
  store.dispatch({ type: HORIZONTAL_LOADING, payload: true });

  const handleRequest = (
    endpoint: any,
    isPostRequest = false,
    payload?: any
  ) => {
    const requestPromise: Promise<AxiosResponse<any>> = isPostRequest
      ? http.post(endpoint, payload)
      : http.get(endpoint);

    return requestPromise
      .then((res: AxiosResponse<any>) => {
        if (res.data.status === "200") {
          store.dispatch({
            type: PROMOS,
            payload: {
              ...promosState,
              ...(requestType === "promos" && {
                promos: sortByTimeDesc(res.data.data.collections),
              }),
              ...(requestType === "promoCodes" && {
                promoCodes: res.data.data,
              }),
              ...(requestType === "referrals" && {
                referrals: res.data.data,
              }),
              ...(requestType === "vouchers" && {
                vouchers: res.data.data,
              }),
            },
          });
        }
      })
      .catch((error) => {
        toastAction({
          show: true,
          type: "error",
          timeout: 20000,
          message: `${error.message}`,
        });
      })
      .finally(() => {
        store.dispatch({ type: HORIZONTAL_LOADING, payload: false });
      });
  };

  if (requestType === "promoCodes") {
    return handleRequest(endpoints.PROMOS_USAGE, true, { flag: "promos" });
  } else if (requestType === "referrals") {
    return handleRequest(endpoints.PROMOS_USAGE, true, { flag: "referrals" });
  } else if (requestType === "vouchers") {
    return handleRequest(endpoints.PROMOS_USAGE, true, { flag: "vouchers" });
  } else {
    // requestType === "promos"
    return handleRequest(endpoints.PROMOS + "?limit=30&offset=0");
  }
};

export const getPromosUsage = () => {
  const promosState = store.getState().promos;
  store.dispatch({ type: HORIZONTAL_LOADING, payload: true });
  http
    .post(endpoints.PROMOS_USAGE, {
      flag: "promos",
      // type: "grouped",
    })
    .then((res) => {
      if (res.data.status === "200")
        // store.dispatch({
        //   type: PROMOS,
        //   payload: { ...promosState, promos: sortByTimeDesc(res.data.data) },
        // });
        store.dispatch({ type: HORIZONTAL_LOADING, payload: false });
    })
    .catch((err) => console.log(err))
    .then(() => {
      store.dispatch({ type: HORIZONTAL_LOADING, payload: false });
    });
};

export const fetchUserNotifications = async () => {
  http
    .get(parseEndpointParameters(endpoints.NOTIFICATIONS, "0"))
    .then((res) => {
      if (res.data.status === "200") {
        store.dispatch({ type: NOTIFICATIONS, payload: [...res.data.data] });
      }
    })
    .catch((err) => console.log(err))
    .then(() => {
      store.dispatch({ type: LOADING, payload: false });
    });
};

export const getUserVerifications = (userId: string, callback: Function) => {
  http
    .get(parseEndpointParameters(endpoints.USER_VERIFICATION, userId))
    .then((res) => {
      if (res.data.status === "200") {
        callback(res.data.data);
      }
    })
    .catch((err) => console.log(err))
    .then(() => {});
};

export const getVerificationData = (
  verificationId: string,
  callback: Function
) => {
  store.dispatch({ type: HORIZONTAL_LOADING, payload: true });
  http
    .get(parseEndpointParameters(endpoints.VERIFICATION_DATA, verificationId))
    .then((res) => {
      if (res.data.status === "200") {
        store.dispatch({ type: HORIZONTAL_LOADING, payload: false });
        callback(res.data.data);
      }
    })
    .catch((err) => console.log(err))
    .then(() => {});
};

export const generateDataSourcePDF = (
  id: number,
  data: any,
  setLoading: Function
) => {
  setLoading(true);
  http
    .post(parseEndpointParameters(endpoints.VERIFICATION_DATA_PDF_EXPORT), {
      id,
      data,
    })
    .then((res: any) => {
      console.log(res, "res");
      setLoading(false);
    })
    .catch((err) => {});
};

export const sendActivationMail = (userId: any) => {
  confirmDialog({
    message: "Are you sure you want to resend activation mail to this user?",
    isPositive: false,
    open: true,
    callback: () => sendMail(),
  });

  const sendMail = () => {
    store.dispatch({ type: LOADING, payload: true });

    http
      .post(parseEndpointParameters(endpoints.USER_ACTIVATION), {
        userId,
      })
      .then((res) => {
        store.dispatch({ type: LOADING, payload: false });
        if (res.data.status === "200") {
          toastAction({
            show: true,
            type: "success",
            timeout: 20000,
            message: `Done. Resent.`,
          });
        } else {
          toastAction({
            show: true,
            type: "error",
            timeout: 20000,
            message: `${res.data.error.message}`,
          });
        }
      })
      .catch()
      .then(() => {
        store.dispatch({ type: LOADING, payload: false });
      });
  };
};

export const updateReferralConfigs = (values: any) => {
  store.dispatch({ type: LOADING, payload: true });

  http
    .put(parseEndpointParameters(endpoints.REFERRALS_CONFIGS), {
      name: "settings",
      data: values,
    })
    .then((res) => {
      store.dispatch({ type: LOADING, payload: false });
      if (res.data.status === "200") {
        localStorage.removeItem("VALUES");
        appValuesAction();

        toastAction({
          show: true,
          type: "success",
          timeout: 20000,
          message: `Referral configs updated.`,
        });
      } else {
        toastAction({
          show: true,
          type: "error",
          timeout: 20000,
          message: `${res.data.error.message}`,
        });
      }
    })
    .catch()
    .then(() => {
      store.dispatch({ type: LOADING, payload: false });
    });
};

export const updateAppValues = () => {
  localStorage.removeItem("VALUES");
  appValuesAction();
};

export const getPromo = async (code: string) => {
  const res = await axios.get(
    parseEndpointParameters(config.API_HOST + endpoints.PROMO, code),
    {
      headers: { "X-SERVICE-PROVIDER": config.SERVICE_PROVIDER },
    }
  );

  if (res.data.status == 200) {
    return res.data.data;
  } else {
    return undefined;
  }
};

export const updateTransferRecipient = (
  callback: Function,
  transferId: any
) => {
  store.dispatch({ type: LOADING, payload: true });
  const userId = store.getState().auth.user?.id;
  const recipient = store.getState().recipients.recipient;

  http
    .put(parseEndpointParameters(endpoints.UPDATE_TRANSFER, transferId), {
      recipient: recipient.id,
    })
    .then((res) => {
      store.dispatch({ type: LOADING, payload: false });
      if (res.data.status === "200") {
        toastAction({
          show: true,
          type: "success",
          timeout: 10000,
          message: "Transfer updated",
        });
        callback?.();
      } else {
        toastAction({
          show: true,
          type: "error",
          timeout: 10000,
          message: "Could not update transfer",
        });
      }
    });
};

export const getCompetitorRates = () => {
  // store.dispatch({type: LOADING, payload: true})
  const competitorState = store.getState().competitorRates;

  http
    .get(
      parseEndpointParameters(
        endpoints.GET_COMPETITOR_RATES_FORMATTED,
        constants.COMPETITOR_RATES_BASE_CURRENCY,
        constants.COMPETITOR_RATES_TARGET_CURRENCY,
        constants.COMPETITOR_RATES_COMPARE_AMOUNT as any
      )
    )
    .then((res) => {
      if (res.data.status === "200") console.log(res.data.data);

      store.dispatch({
        type: COMPETITOR_RATES,
        payload: {
          ...competitorState,
          competitorRates: sortByTimeDesc(res.data.data),
        },
      });
      store.dispatch({ type: LOADING, payload: false });
    })
    .catch((err) => console.log(err))
    .then(() => {
      store.dispatch({ type: LOADING, payload: false });
    });
};

export const createCompetitorRate = (
  values: any,
  callback: Function,
  editId: any = false
) => {
  store.dispatch({ type: LOADING, payload: true });

  (editId
    ? http.put(
        parseEndpointParameters(
          endpoints.POST_COMPETITOR_RATES,
          constants.COMPETITOR_RATES_BASE_CURRENCY,
          constants.COMPETITOR_RATES_TARGET_CURRENCY
        ),
        {
          ...values,
        }
      )
    : http.post(
        parseEndpointParameters(
          endpoints.POST_COMPETITOR_RATES,
          values.baseCurrency,
          values.targetCurrency
        ),
        {
          ...values,
        }
      )
  )
    .then((res: any) => {
      store.dispatch({ type: LOADING, payload: false });
      if (res.data.status === "200") {
        toastAction({
          show: true,
          type: "success",
          timeout: 20000,
          message: `Rate created.`,
        });

        callback?.();
      } else {
        toastAction({
          show: true,
          type: "error",
          timeout: 20000,
          message: `${res.data.error.message}`,
        });
      }
    })
    .catch(() => {})
    .then(() => {
      store.dispatch({ type: LOADING, payload: false });
    });
};

export const saveSpreads = (payload: { spreads: any }, callback?: Function) => {
  store.dispatch({ type: LOADING, payload: true });

  http
    .post(parseEndpointParameters(endpoints.EXCHANGE_RATE_SPREADS), payload)
    .then((res) => {
      store.dispatch({ type: LOADING, payload: false });
      if (res.data.status === "200") {
        toastAction({
          show: true,
          type: "success",
          timeout: 10000,
          message: "Exchange rate spread updated",
        });
        callback?.();
      } else {
        toastAction({
          show: true,
          type: "error",
          timeout: 10000,
          message: res.data?.error?.message || "Could not update spread",
        });
      }
    });
};

export const getSpreads = () => {
  store.dispatch({ type: LOADING, payload: true });

  http
    .get(parseEndpointParameters(endpoints.EXCHANGE_RATE_SPREADS))
    .then((res) => {
      if (res.data.status === "200") {
        store.dispatch({ type: EXCHANGE_SPREADS, payload: [...res.data.data] });
      }
    })
    .catch((err) => console.log(err))
    .then(() => {
      store.dispatch({ type: LOADING, payload: false });
    });
};

export const updateCurrencyPairDowntime = (payload: {
  targetCurrency: string;
  baseCurrency: string;
  status: boolean;
}) => {
  http
    .put(parseEndpointParameters(endpoints.EXCHANGE_RATE_SPREADS), {
      ...payload,
    })
    .then((res) => {
      if (res.data.status === "200") {
        store.dispatch({ type: EXCHANGE_SPREADS, payload: [...res.data.data] });
        toastAction({
          show: true,
          type: "success",
          timeout: 15000,
          message: `Currency pair downtime updated`,
        });
      } else {
        toastAction({
          show: true,
          type: "error",
          timeout: 25000,
          message: res.data.error.message,
        });
      }
    });
};

export const updateUserStatus = (userId: number, status: any) => {
  // confirmDialog({
  //   message: `Are you sure you want to update this account to ${status}?`,
  //   isPositive: true,
  //   open: true,
  //   callback: () => confirmedUpdate(),
  // });
  confirmDialog({
    message: `Please, input your account password to make this change`,
    isPositive: undefined,
    open: true,
    field: {
      title: "Password:",
      placeholder: "Your account password here...",
      required: true,
    },
    callback: (fieldValue: string) =>
      confirmUserPassword(fieldValue, confirmedUpdate),
  });
  const confirmedUpdate = () => {
    http
      .put(parseEndpointParameters(endpoints.USER_STATUS), {
        userId,
        status,
      })
      .then((res) => {
        if (res.data.status === "200") {
          getUsers();
          toastAction({
            show: true,
            type: "success",
            timeout: 15000,
            message: `User status updated`,
          });
        } else {
          toastAction({
            show: true,
            type: "error",
            timeout: 25000,
            message: res.data.error.message,
          });
        }
      });
  };
};

export const getUserUploads = (userId: string, callback: Function) => {
  store.dispatch({ type: LOADING, payload: true });

  http
    .get(parseEndpointParameters(endpoints.USER_FILE_UPLOAD, userId))
    .then((res) => {
      if (res.data.status === "200") {
        callback(res.data.data);
      }
    })
    .catch((err) => console.log(err))
    .then(() => {
      store.dispatch({ type: LOADING, payload: false });
    });
};
