/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { IoIosMore } from "react-icons/io";

import {
  DeleteOutlined,
  FlagFilled,
  ReloadOutlined,
  SendOutlined,
  UserOutlined,
} from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  deleteUser,
  getUsers,
  sendActivationMail,
} from "src/redux/actions/actions";
import { paths } from "src/util/paths";

import { Badge, Button, Dropdown, Space, Table, Tag, Tooltip } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";

import { USERS } from "src/redux/actionTypes";
import store from "src/redux/store";
import constants from "src/util/constants";
import { convertDateString, replaceUnderscores } from "src/util/util";
import {
  buildQueryParams,
  getUserKycVerificationTagColor,
  getUserStatusTagColor,
  parseQueryString,
} from "src/util/utility";
import styled from "styled-components";
import DownloadData from "../../parts/DownloadData";

interface TransactionTableProps {
  tableBody?: any[];
  currentUsersPage: number;
  setFilterQueryParams: React.Dispatch<React.SetStateAction<string>>;
}

interface DataType {
  key: React.Key;
  name: string;
  customerID: string;
  country: string;
  date: string;
  kyc: string;
  account_verification: string;
  action: string;
}

export default function UsersTable({
  tableBody,
  currentUsersPage,
  setFilterQueryParams,
}: TransactionTableProps) {
  const history = useHistory();
  const dispatch = useDispatch();
  const countries = useSelector((state: any) => state.appValues.countries);
  const { total } = useSelector((state: any) => state.users);
  const users = store.getState().users;

  // table - column and data
  const [columnData, setColumnData] = React.useState<any>();
  const [datasrc, setDatascr] = React.useState<any>([]);

  const [openDownload, setOpenDownload] = React.useState(false);

  // csv - column and row data
  const [header, setHeader] = React.useState<any>([]);
  const [csvData, setCSVData] = React.useState<any[]>([]);

  // row selection and relod indicator state
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const [loading, setLoading] = React.useState(false);

  const [pageSize, setPageSize] = React.useState(10);

  // set reloding to false after 1s
  const start = () => {
    setLoading(true);
    // ajax request after empty completing
    setTimeout(() => {
      setSelectedRowKeys([]);
      setLoading(false);
    }, 1000);
  };

  // handle on row selected change
  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: DataType[]
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
    // custom filed to be printed on csv or pdf
    splitSlectedData(
      ["name", "customerID", "country", "date", "kyc", "account_verification"],
      selectedRows
    );
  };

  // on row selected
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  // handles numbwe of selected rowa
  const hasSelected = selectedRowKeys.length > 0;

  // actions dropdown contetnt
  const items = [
    // {
    //   key: "1",
    //   label: (
    //     <ActionButtons
    //       details={getCorrespondingUserActions(
    //         columnData?.status,
    //         getUserActionCallbacks(columnData)
    //       )}
    //     />
    //   ),
    // },
    {
      key: "1",
      label: (
        <a
          onClick={() => {
            history.push(paths.TRANSFER_METHOD + `?user=${columnData.id}`);
          }}
        >
          Send Transfer for
        </a>
      ),
      icon: <SendOutlined />,
    },
    {
      key: "2",
      label: (
        <a
          onClick={() => {
            sendActivationMail(columnData.id);
          }}
        >
          Resend activation mail
        </a>
      ),
      icon: <ReloadOutlined />,
    },
    {
      key: "3",
      label: (
        <a
          onClick={() => {
            history.push(`/app/users/${columnData?.id}`);
          }}
        >
          view details
        </a>
      ),
      icon: <UserOutlined />,
    },
    {
      key: "4",
      label: (
        <a
          onClick={() => {
            deleteUser(() => getUsers(), columnData);
          }}
        >
          Delete user
        </a>
      ),
      icon: <DeleteOutlined />,
      danger: true,
    },
  ];

  // column data @ant-design table
  const columns: ColumnsType<DataType> = [
    {
      title: "Name",
      dataIndex: "name",
      width: 250,
      render: (user) => (
        <NameAndEmailWarpper>
          <span>{`${user.profile?.firstName} ${user.profile?.lastName}`}</span>
          <span>{user.username}</span>
        </NameAndEmailWarpper>
      ),
    },
    {
      title: "Customer ID",
      dataIndex: "customerID",
    },
    {
      title: "Country / IP",
      dataIndex: "country",
      ellipsis: {
        showTitle: false,
      },
      render: (country) => (
        <Tooltip placement="topLeft" title={country}>
          {country}
        </Tooltip>
      ),
    },
    {
      title: "Date joined",
      dataIndex: "date",
    },
    {
      title: "Customer KYC",
      dataIndex: "kyc",
      render: (kyc) => {
        //Todo: reomove FE logic getUserVerificationsLevel
        //const kycLevel = getUserVerificationsLevel(kyc).verificationLevel;
        return (
          <Tag title={kyc} color={getUserKycVerificationTagColor(kyc)}>
            {replaceUnderscores(kyc)}
          </Tag>
        );
      },
    },
    {
      title: "Risk score",
      dataIndex: "riskScore",
    },
    {
      title: "Email Verification",
      dataIndex: "email_verification",
      render: (email_verification) => (
        <Badge
          status={email_verification === "1" ? "success" : "error"}
          text={email_verification === "1" ? "Active" : "Inactive"}
        />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => (
        <Tag color={getUserStatusTagColor(status)}>
          {replaceUnderscores(status)}
        </Tag>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      fixed: "right",
      width: 100,
      render: (action) => (
        <Space size="middle" onClick={(e) => e.stopPropagation()}>
          <Dropdown menu={{ items }}>
            <Space align="center" size="large">
              <a onMouseEnter={() => setColumnData(action)}>
                <IoIosMore size={24} color="#CCCCCC" />
                {action?.status?.toUpperCase() ===
                  constants.USER_STATUS_WATCHLIST_HIT && (
                  <FlagFilled style={{ color: "#a61a2b" }} />
                )}
              </a>
            </Space>
          </Dropdown>
        </Space>
      ),
    },
  ];

  // datasource @ant-design table
  React.useEffect(() => {
    const dataSource: any = [];
    tableBody?.map((item, index) => {
      const data = {
        key: index,
        name: item,
        customerID: `${item?.referral.code}`,
        id: item.id,
        riskScore: item.riskScore || "--",
        metaID: item.meta.customerId,
        country: `${countries?.[item.profile.location_country]}`,
        date: `${convertDateString(item.dateCreated)}`,
        kyc: item?.meta?.flag,
        status: item?.status || "--",
        email_verification: `${item.meta.confirmed}` || "--",
        action: item,
      };
      dataSource.push(data);
    });
    setDatascr(dataSource);
  }, [tableBody]);

  // separete the head and datasource into header and csv for easy download
  const splitSlectedData = (keys: string[], data: any[]) => {
    const newHeader: any = keys.map((key: string) => {
      // @ts-ignore
      const column = columns.find((col) => col.dataIndex === key);
      return column ? column.title : `Column with key '${key}' not found.`;
    });

    const newCSVData: any[] = data.map((item: any) => {
      const newRow: any = {};
      keys.forEach((key) => {
        // @ts-ignore
        const column = columns.find((col) => col.dataIndex === key);
        if (column) {
          // @ts-ignore
          newRow[column.title] = item[key];
        }
      });
      return newRow;
    });

    setHeader(newHeader);
    setCSVData(newCSVData);
  };

  // handle limit and offset change on the table

  React.useEffect(() => {
    return updateUserData();
  }, [currentUsersPage, pageSize]);

  function updateUserData() {
    const limit = pageSize;
    let offset = (currentUsersPage - 1) * limit;

    setFilterQueryParams((prev) => {
      const parsePrevString = parseQueryString(prev);
      const newQuery = {
        ...parsePrevString,
        limit: limit,
        offset: offset,
      };

      return buildQueryParams(newQuery);
    });
  }

  const handleTableChange = (pagination: TablePaginationConfig) => {
    if (pagination && pagination.current) {
      setPageSize(Number(pagination?.pageSize));
      dispatch({
        type: USERS,
        payload: {
          ...users,
          currentUsersPage: pagination.current,
        },
      });
    }
  };

  return (
    <div>
      {openDownload && (
        <DownloadData
          header={header}
          data={csvData}
          open={openDownload}
          setOpen={setOpenDownload}
          type="User"
        />
      )}

      <Table
        onChange={handleTableChange}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={datasrc}
        pagination={{
          position: ["bottomCenter"],
          pageSizeOptions: [10, 20, 50, 100],
          total: total,
          current: currentUsersPage,
        }}
        footer={() => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Tooltip title="Click to reload selected user(s)">
                <Button
                  onClick={start}
                  disabled={!hasSelected}
                  loading={loading}
                >
                  Reload
                </Button>
              </Tooltip>
              <span style={{ marginLeft: 8 }}>
                {hasSelected
                  ? ` ${selectedRowKeys.length} user(s) selected`
                  : ""}
              </span>
            </div>
            <Tooltip title="Click to dwonload selected user(s)">
              <Button
                disabled={!hasSelected}
                onClick={() => setOpenDownload(true)}
              >
                Download
              </Button>
            </Tooltip>
          </div>
        )}
        scroll={{ x: 1400 }}
      />
    </div>
  );
}

export const NameAndEmailWarpper = styled.span`
  display: flex;
  flex-direction: column;
  gap: 0px;
  width: 250px;

  span {
    width: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  span:last-child {
    color: #808080;
    font-size: 12px;
    font-weight: 500;
  }
`;
