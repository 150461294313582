import { useQuery } from "react-query";
import endpoints from "src/util/endpoints";
import { getRequest } from "src/util/http";

export const useQuickRemitData = (enabled: boolean) => {
  const endpoint = endpoints.QUICK_REMIT_INFO;
  return useQuery(
    endpoint,
    () => getRequest(endpoint, "Failed to fetch quick remit info"),
    {
      enabled,
      staleTime: 5 * 60 * 1000, // 5 minutes
      refetchInterval: 5 * 60 * 1000, // Refetch every 5 minutes
      refetchOnWindowFocus: false,
    }
  );
};

export const getCardColor = (title: string) => {
  switch (title) {
    case "Available Balance":
      return "rgba(83, 196, 26, 0.04)";
    case "Pending":
      return "rgba(250, 173, 20, 0.04)";
    default:
      return "rgb(44, 45, 44, 0.04)";
  }
};

export const getBalanceHelpMessage = (title: string): string => {
  switch (title) {
    case "Available Balance":
      return "This is the available balance, excluding pending transactions.";
    case "Ledger Balance":
      return "This is the total balance, including pending transactions.";
    case "Pending":
      return "These are funds that are being processed and will be available soon.";
    case "Hold":
      return "These are funds that have been initiated but are not yet processed.";
    default:
      return "";
  }
};

export const formatTimeAgo = (timestamp: string | number) => {
  const now = new Date();
  const updatedAt = new Date(timestamp);
  const diffInSeconds = Math.floor(
    (now.getTime() - updatedAt.getTime()) / 1000
  );

  if (diffInSeconds < 60) {
    return `${diffInSeconds} sec ago`;
  }

  const diffInMinutes = Math.floor(diffInSeconds / 60);
  if (diffInMinutes < 60) {
    return `${diffInMinutes} min${diffInMinutes > 1 ? "s" : ""} ago`;
  }

  const diffInHours = Math.floor(diffInMinutes / 60);
  if (diffInHours < 24) {
    return `${diffInHours} hr${diffInHours > 1 ? "s" : ""} ago`;
  }

  const diffInDays = Math.floor(diffInHours / 24);
  if (diffInDays < 30) {
    return `${diffInDays} day${diffInDays > 1 ? "s" : ""} ago`;
  }

  const diffInMonths = Math.floor(diffInDays / 30);
  if (diffInMonths < 12) {
    return `${diffInMonths} month${diffInMonths > 1 ? "s" : ""} ago`;
  }

  const diffInYears = Math.floor(diffInMonths / 12);
  return `${diffInYears} year${diffInYears > 1 ? "s" : ""} ago`;
};
